import { TweenLite } from "gsap";

export default class Expander {
  /**
   * Get all instances of the expander
   * @type {String}
   */
  constructor(el) {
    this.container = el;
    this.body = document.querySelector('body');
    this.title = this.container.querySelector('.expander__title');
    this.trigger = this.container.querySelector('[data-expand-trigger]');
    this.content = this.container.querySelector('[data-expand-content]');
    this.duration = 300;
    this.open = false;

    this.init();
    this.events();
  }

  init() {
	  /*
    if ( this.body.classList.contains('page-id-1684') ) {
      this.container.classList.add('contains-icons');
      let iconsContainer = document.createElement('div');
      iconsContainer.classList.add('icons-container');

      const iconNames = ['chat_bubble', 'textsms', 'call', 'email'];
      iconNames.forEach(name => {
        let icon = document.createElement('i');
        icon.classList.add('material-icons');
        icon.classList.add(`material-icons-${name}`);
        icon.innerHTML = name;
        iconsContainer.appendChild(icon);
      });

      if ( this.title ) {
        this.title.appendChild(iconsContainer);
      }
    }*/
    
    // items that are open on load
    if (this.container.classList.contains('active')) {
      this.open = true;
      this.slideOpen();
    }
  }

  /**
   * Bind all expander triggers
   */
  events(){
    // Pass the container being triggered
    this.trigger.addEventListener('click', this.toggle.bind(this));
  }

  /**
   * Toggle the state on the expander
   */
  toggle(e){
    this.open ? this.slideClose() : this.slideOpen();
  }

  /**
   * Open the expander content
   */
  slideOpen(){
    TweenLite.set(this.content, { height: 'auto', alpha: 0, y: 0 });
    TweenLite.from(this.content, 0.5, { ease: Power2.easeInOut, height: 0, y: -20 });
    TweenLite.to(this.content, 0.5, { ease: Power2.easeInOut, alpha: 1 });
    this.open = true;
    this.toggleClass();
  }

  /**
   * Close the expander content
   */
  slideClose(){
    TweenLite.to(this.content, 0.5, { ease: Power2.easeInOut, height: 0, alpha: 0, y: -20 });
    this.open = false;
    this.toggleClass();
  }

  /**
   * Toggle the active class of the element
   */
  toggleClass(){
    this.open ? this.container.classList.add('active') : this.container.classList.remove('active');
  }
}
