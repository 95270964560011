import jump from 'jump.js'

export default class Footer {
  constructor() {
    this.bodyEl = document.querySelector('body')
    this.footerEl = document.getElementById('site-footer')
    this.footerTrigger = this.footerEl.querySelector('.site-footer__mobile-trigger')
    this.footerContent = this.footerEl.querySelector('.site-footer__content')

    this.events()
    this.footerOpen = false
  }

  events() {
    this.footerTrigger.addEventListener('click', this.footerTriggerHandle.bind(this))

    window.addEventListener('resize', this.updateFooter.bind(this))
  }

  footerTriggerHandle() {
    this.footerOpen ? this.closeFooter() : this.openFooter()
  }

  openFooter() {
    this.footerOpen = true;
    this.bodyEl.classList.add('reveal-footer')
    const last = this.footerContent.getBoundingClientRect()

    this.footerContent.style.height = '0px'
    this.footerContent.style.transition = 'height 0.3s ease'

    // Forced delay to give browser room
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        const _this = this;
        this.footerContent.style.height = `${last.height}px`

        this.footerContent.addEventListener('transitionend', function transEnd(){
          _this.slideToFooter()
          _this.footerContent.removeEventListener('transitionend', transEnd);
        })
      })
    })

  }

  closeFooter() {
    const _this = this;
    this.footerOpen = false;
    this.footerContent.style.height = '0px'


      // Forced delay to give browser room
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        this.footerContent.addEventListener('transitionend', function transEnd(){
          _this.footerContent.style.height = 'auto'
          _this.slideToFooter()
          _this.bodyEl.classList.remove('reveal-footer')
          _this.footerContent.removeEventListener('transitionend', transEnd);
        })
      })
    })
  }

  slideToFooter() {
    // console.log(this.footerContent)
    jump(this.footerTrigger)
  }

  updateFooter() {
    if(window.matchMedia('min-width: 600px')){
      this.footerContent.style.height = 'auto'
      this.footerOpen = false
    }else{
      this.closeFooter()
    }
  }
}