export default class eservicesPage {

	constructor(el) {
		this.el = el;
		this.events();
	}

	events() {

	}
}