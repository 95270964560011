import { getClosest as closest } from '../helpers/helpers'
import throttle from 'lodash.throttle'
import jump from 'jump.js'


export default class SectionExpanders {
  constructor(el){
    this.el = el
    this.body = document.querySelector('body')
    this.expanderContent = this.el.querySelector('.section-expander__content')
    this.expanderInfo = this.el.querySelector('.section-expander__content-info')
    this.triggers = Array.from(this.el.querySelectorAll('[data-section-expander-trigger]'))
    this.learnMore = this.el.querySelector('.section-expander__learn-more')

    this.siteHeader = document.querySelector('.site-header')
    this.siteHeaderRect = this.siteHeader.getBoundingClientRect()
    this.offset = 10

    this.open = false
    this.inTransition = false

    this.events()

    // Allows fallback if no .js-enabled, show the content
    if(!this.body.classList.contains('js-enabled')) this.body.classList.add('js-enabled')
  }

  events() {

    if ( this.learnMore ) {
      // Throttle scroll watcher for performance
      const throttledDraw = throttle(this.scrollArticleWatcher.bind(this), 60).bind(this)
      this.scrollWatchHandle = throttledDraw
    }

    // Trigger handler
    this.triggerHandle = this.triggerEvent.bind(this)
    this.triggers.forEach(el => {
      el.addEventListener('click', this.triggerHandle)
    })
  }

  // Toggle listener
  triggerEvent(evt) {
    evt.preventDefault()
    this.open ? this.closeContent() : this.openContent()
  }

  // Open up the expander
  openContent() {
    const _this = this
    this.scrollToExpander()

    // To enable .focus()
    this.expanderInfo.setAttribute('tabindex', '-1')

    this.open = true
    this.inTransition = true

    // Get the position in the active state to get the value to animate to
    if ( this.learnMore ) {
      this.learnMore.classList.add('active')
    }
    this.el.classList.add('expanding')
    const last = this.expanderContent.getBoundingClientRect()

    this.expanderContent.style.height = 0 + 'px'
    this.expanderContent.style.overflow = 'hidden'

    this.expanderContent.style.transition = 'height 0.3s ease, transform 0.3s ease'

    // Nested within RAF to allow browser the read and write values 
    // before applying transformation otherwise often skips.
    // Double wrapped as it can happen at between frames.
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        this.expanderContent.style.height = `${last.height}px`
        this.watchWindowScroll()

        this.expanderContent.addEventListener('transitionend', function transEnd(){
          _this.inTransition = false
          _this.el.classList.add('expanded')
          _this.el.classList.remove('expanding')
          _this.expanderContent.removeEventListener('transitionend', transEnd)
        })

        if(window.matchMedia('min-width: 600px')){
          this.expanderInfo.focus()
        }
      })
    })
  }

  // Close the expander
  closeContent() {
    const _this = this

    // Resetting height
    this.expanderContent.style.height = 0 + 'px'
    this.el.classList.add('closing')
    this.inTransition = true
    this.unwatchWindowScroll()

    if ( _this.learnMore ) {
      _this.learnMore.classList.remove('active')
      _this.learnMore.classList.remove('expanded')
      if(_this.stuckBottom || _this.stuckTop) _this.replaceTrigger()
    }


    this.expanderContent.addEventListener('transitionend', function transEnd(){
      // Removing self eventListener
      _this.expanderContent.removeEventListener('transitionend', transEnd)

      _this.expanderContent.style.height = 'auto'
      _this.expanderContent.style.transform = 'none'

      _this.el.classList.remove('closing')

      // Reset the stage
      _this.open = false
      _this.inTransition = false
      _this.el.classList.remove('expanded')
    })
  }

  watchWindowScroll(){
    // Watch the scroll for the current article
    window.addEventListener('scroll', this.scrollWatchHandle)
  }

  unwatchWindowScroll(){
    window.removeEventListener('scroll', this.scrollWatchHandle)
  }

  // Watches scrolling 
  scrollArticleWatcher(e){
    // Forces dom layout so should be done behind a throttle as expensive
    const elRect = this.expanderContent.getBoundingClientRect()
    const learnMoreRect = this.learnMore.getBoundingClientRect();
    // console.log('height:', elRect.height, '- top:', elRect.top, '- bottom:', elRect.bottom);
    // Used for checking if at the bottom
    const bottomThreshold = (learnMoreRect.height + 40)
    const topThreshold = (elRect.top - this.siteHeaderRect.height)

    if(!this.inTransition){

      if(topThreshold < 0 && elRect.bottom > bottomThreshold && !this.stuckTop){
        // console.log('stick to top');
        this.stickTriggerTop()
      }else if(elRect.bottom < bottomThreshold && !this.stuckBottom){
        // console.log('Attach to bottom');
        this.stickTriggerBottom()
      }else if(topThreshold > 0 && this.stuckTop){
        // console.log('Everyday location');
        this.replaceTrigger()
      }
    }
  }

  // Placing learnMore at the top in the DOM
  // This allows position fixed to work correctly whilst scrolling
  // with in an element
  stickTriggerTop(){
    this.cloneLearnMore()
    this.learnMore.classList.add('top')
    this.learnMore.classList.add('expanded')
    this.body.insertBefore(this.learnMore, this.body.firstChild)
    this.stuckTop = true
    this.stuckBottom = false
  }

  // Placing learnMore at the bottom
  stickTriggerBottom(){
    this.cloneLearnMore()
    this.learnMore.classList.add('bottom')
    this.expanderInfo.appendChild(this.learnMore)
    this.stuckTop = false
    this.stuckBottom = true
  }

  // Resetting to default position
  replaceTrigger(){
    this.cloneLearnMore()
    const insertAfterThis = this.el.querySelector('.section-expander__header__content')

    this.stuckTop = false
    this.stuckBottom = false
    insertAfterThis.parentNode.insertBefore(this.learnMore, insertAfterThis.nextSibling)
  }

  cloneLearnMore(){
    // Clone the trigger and clean up listeners, remove from DOM
    const learnClone = this.learnMore.cloneNode(true)
    this.learnMore.removeEventListener('click', this.triggerHandle)
    this.learnMore.parentNode.removeChild(this.learnMore)

    // Clean up classes
    learnClone.classList.remove('bottom')
    learnClone.classList.remove('top')

    // Apply items back with eventListener
    // Now can be placed wherever in the DOM
    this.learnMore = learnClone
    this.learnMore.addEventListener('click', this.triggerHandle)
  }

  destroy(){
    this.closeContent()
  }

  scrollToExpander(){
    jump(this.el, {
      duration: 700,
      offset: 40
    })
  }
}