// Work with IDs or target values - if data-tab-trigger attribute is empty, get href
// Namespace tabs ability

/**
 * Pass in scope of tabs - the container that fits all tab content
 * Example usage using a data-tabs attribute on the container:

  this.tabs = Array.from(document.querySelectorAll('[data-tabs]'))
  this.tabs.forEach(el => new Tabs(el))

  Also can expand
*/
import { open, close } from '../helpers/slideHandler'
import { getClosest } from '../helpers/helpers'
import debounce from 'lodash.debounce'
import Sticky from 'sticky-js'
import jump from 'jump.js'

export default class Tabs {
  constructor(el) {
    this.container = el;
    this.nav = document.getElementById('navigation')
    this.navDimensions = this.nav.getBoundingClientRect()
    this.tabsTriggers = Array.from(this.container.querySelectorAll('[data-tabs-trigger]'))
    this.tabs = Array.from(this.container.querySelectorAll('[data-tabs-target]'))
    this.tabExpandTriggers = Array.from(document.querySelectorAll('.tab__expand-trigger'))
    this.tabContents = Array.from(document.querySelectorAll('.tab__content'))

    this.windowWidth = window.innerWidth

    this.activateSticky()
    this.events()
  }

  events(){
    this.tabsTriggers.forEach(el => {
      el.addEventListener('click', this.handleClick.bind(this, el))
    })

    this.tabExpandTriggers.forEach(el => {
      el.addEventListener('click', this.toggleExpand.bind(this, el))
    })

    this.debounceResize = debounce(this.resizeEvent.bind(this), 100).bind(this)
    window.addEventListener('resize', this.debounceResize)
  }

  handleClick(el, e){
    e.preventDefault();
    let targetId = el.getAttribute('href');
    let target = this.container.querySelector(targetId);

    this.resetTabs();
    this.resetNav();

    el.classList.add('active');
    this.show(target);
  }

  show(target){
    target.classList.add('tab-active');
  }

  resetTabs(){
    this.tabs.forEach(el => el.classList.remove('tab-active'))
  }

  resetNav(){
    for (var i = 0; i < this.tabsTriggers.length; i++) {
      this.tabsTriggers[i].classList.remove('active');
    }
  }

  toggleExpand(trigger, evt){
    const parent = getClosest(trigger, '[data-tabs-target]')
    const target = parent.querySelector('.tab__content')

    parent.classList.contains('expanded') ? this.closeExpander(parent, target) : this.openExpander(parent, target)
  }

  openExpander(parent, target){
    open(target)
    parent.classList.add('expanded')
  }

  closeExpander(parent, target){
    close(target)
    parent.classList.remove('expanded')
  }

  resetExpanders(){
    this.tabContents.forEach(el => {
      el.style.display = ''
      el.style.height = ''
    })

    this.tabs.forEach(el => { el.classList.remove('expanded') })
  }

  resizeEvent(){
    if(window.innerWidth !== this.windowWidth){
      this.resetExpanders()
      this.windowWidth = window.innerWidth
    }
  }

  activateSticky(){
    new Sticky('[data-sticky-element]', {
      // Make it snap to the navbar
      marginTop: (this.navDimensions.height + this.navDimensions.top),
      wrap: true
    })
  }

}
