import { isTouchDevice } from '../helpers/helpers';

export default class Emergency {

  constructor(el) {

    this.el = el;
    // this.htmlEl = document.querySelector('html');
    this.bodyEl = document.querySelector('body');
    this.trigger = this.el.querySelector('.emergency-nav__trigger');
    this.tooltip = this.el.querySelector('.emergency-nav__tooltip');
    this.overlay = this.el.querySelector('.emergency-nav__overlay');
    this.closeBtn = this.el.querySelector('.emergency-nav__close');
    // this.list = this.el.querySelector('.emergency-nav__list');
    // this.listItems = document.querySelectorAll('.emergency-nav__list-item');
    this.infoTooltip = document.querySelector('.info-tooltip');

    this.hovered = false;
    this.tooltipDisplayed = sessionStorage.tooltipDisplayed;

    this.events();

  }

  events() {

    if ( this.infoTooltip ) {
      this.el.parentNode.removeChild(this.el);
      return;
    }

    if (!this.tooltipDisplayed) {
      setTimeout(() => {
        if (!this.hovered) {
          this.showTooltip();

          setTimeout(() => {
            if (!this.hovered) {
              this.hideTooltip();
            }
          }, 5000);
        }
      }, 2000);
    }

    this.el.classList.add('active');

    this.trigger.addEventListener('click', this.openModal.bind(this));

    this.overlay.addEventListener('click', e => {
      if (e.target === this.overlay) {
        this.closeModal();
      }
    });

    this.closeBtn.addEventListener('click', this.closeModal.bind(this));

    if ( isTouchDevice() ) {
      this.trigger.addEventListener('touchstart', this.mouseoverHandle.bind(this));
      this.trigger.addEventListener('touchend', this.hideTooltip.bind(this));
    } else {
      this.trigger.addEventListener('mouseover', this.mouseoverHandle.bind(this));
      this.trigger.addEventListener('mouseout', this.hideTooltip.bind(this));
    }

  }

  mouseoverHandle() {
    this.hovered = true;
    this.showTooltip();
  }

  showTooltip() {
    this.tooltip.classList.add('active');

    if (!this.tooltipDisplayed) {
      sessionStorage.setItem('tooltipDisplayed', true);
    }
  }

  hideTooltip() {
    this.tooltip.classList.remove('active');
  }

  openModal() {
    this.hovered = true;
    this.hideTooltip();
    this.bodyEl.classList.add('emergency-nav-open');
    // this.htmlEl.classList.add('no-scroll');
  }

  closeModal() {
    this.bodyEl.classList.remove('emergency-nav-open');
    // this.htmlEl.classList.remove('no-scroll');
  }

}