const DURATION_DEFAULT = 300

export function open(el, duration = DURATION_DEFAULT){
  let targetHeight = getHeight(el);
  let animationSettings = `transition: height ${duration}ms linear;\
                          height: 0px;\
                          display: block;\
                          overflow: hidden;`;
  // Set the styles for animation
  el.setAttribute('style', animationSettings);
  el.classList.add('expanding')

  // RAF required to queue setting the height, otherwise transition skips
  window.requestAnimationFrame(() => {
    el.style.height = targetHeight + 'px';
    el.addEventListener('transitionend', function transEnd (){
      el.classList.add('expanded')
      el.classList.remove('expanding')
      el.style.height = 'auto';
      el.removeEventListener('transitionend', transEnd);
    })
  });
}

export function close(el, duration = DURATION_DEFAULT){
  let st = el.style;
  st.height = getHeight(el) + 'px'

  // Yup, double wrapped to ensure the property is changed after the other frame has rendered
  window.requestAnimationFrame(()=>{
    window.requestAnimationFrame(()=>{
      st.height = '0px'
    })
  })

  el.addEventListener('transitionend', function transEnd (){
    st.display = 'none'
    st.height = 'auto'
    el.removeEventListener('transitionend', transEnd);
  })
}

export function getHeight(el){
  const el_style = window.getComputedStyle(el)
  const el_display = el_style.display
  const el_position = el_style.position
  const el_visibility = el_style.visibility
  const el_max_height = el_style.maxHeight.replace('px', '').replace('%', '')
  let wanted_height = 0;

  // if its not hidden we just return normal height
  if(el_display !== 'none' && el_max_height !== '0') {
      return el.offsetHeight;
  }

  // the element is hidden so:
  // making the el block so we can meassure its height but still be hidden
  el.style.position = 'relative';
  el.style.visibility = 'hidden';
  el.style.display = 'block';

  wanted_height = el.offsetHeight;

  // reverting to the original values
  el.style.display = el_display;
  el.style.position = el_position;
  el.style.visibility = el_visibility;

  return wanted_height;
}