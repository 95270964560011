// Used in the building of the first intro page
export default class Home {
  constructor(){
    this.formEl = document.forms['signupForm']
    this.signUpTrigger = document.querySelector('.sign-up__trigger')

    this.footerCTAs = document.querySelector('.footer-ctas')
    this.signUpForm = document.querySelector('.signup-form')
    this.siteFooter = document.querySelector('.site-footer')
    this.firstFormField = document.getElementById('6475053')

    this.events()
  }

  events(){
    this.formEl.onsubmit = this.submitForm.bind(this)

    this.signUpTrigger.addEventListener('click', this.openSignUp.bind(this))
  }

  submitForm(e){
    e.preventDefault()

    if(!this.validateInputs()) return;
    console.log('submitting form', e);

    var XHR = new XMLHttpRequest();
    var formData  = new FormData(this.formEl);

    // XHR.addEventListener("load", function(event) {
    //   alert(event.target.responseText);
    // });

    // // We define what will happen in case of error
    // XHR.addEventListener("error", function(event) {
    //   console.log(event);
    // });

    XHR.open("POST", "https://secure.campaigner.com/CSB/Public/ProcessHostedForm.aspx");

    XHR.send(formData);
    this.formSubmitted()
  }

  formSubmitted(){
    console.log('form submitted');
    this.siteFooter.classList.add('form-submitted')
    setTimeout(() => {
      // this.footerCTAs.classList.remove('inactive')
      this.signUpForm.classList.remove('active')
      this.siteFooter.classList.remove('form-submitted')
      this.siteFooter.classList.add('reset-footer')
    }, 2000);
  }

  validateInputs(){
    let inputs = this.formEl.querySelectorAll('[required]')
    for (var i = 0; i < inputs.length; i++) {
      if(inputs[i].value === ''){
        inputs[i].classList.add('error-empty')
        inputs[i].focus()
        return false;
      }else if(inputs[i].classList.contains('email')){
        if(!this.validateEmail(inputs[i].value)){
          inputs[i].value = ''
          inputs[i].setAttribute('placeholder', 'Need a valid email address')
          inputs[i].classList.add('error-empty')
          inputs[i].focus()
          return false;
        }
      }else{
        inputs[i].classList.remove('error-empty')
      }
    }
    return true;
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  openSignUp(e){
    console.log('sign-up open', e);
    this.footerCTAs.classList.add('inactive')
    this.signUpForm.classList.add('active')
    setTimeout(() => {
      this.firstFormField.focus()
    }, 300);
  }



}