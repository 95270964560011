export default class RippleExpand {
  constructor(el){
    this.el = el
    this.htmlEl = document.querySelector('html')
    this.trigger = this.el.querySelector('[info-trigger]')
    this.scrollLocking = this.el.getAttribute('data-no-scroll')
    this.open = false

    this.events()
  }

  events(){
    this.watchBodyHandle = this.closeRipple.bind(this)
    this.elPropagationHandle = (e) => e.stopPropagation()

    if (this.trigger) this.trigger.addEventListener('click', this.toggleRipple.bind(this))
  }

  toggleRipple(){
    this.open ? this.closeRipple() : this.openRipple()
  }

  closeRipple(){
    this.open = false
    this.el.classList.remove('expanded')
    if(this.scrollLocking) this.htmlEl.classList.remove('no-scroll')
    this.unWatchBody()
  }

  openRipple(){
    this.open = true
    this.el.classList.add('expanded')
    if(this.scrollLocking) this.htmlEl.classList.add('no-scroll')
    
    setTimeout(() => {
      this.watchBody()
    }, 100);
  }

  watchBody() {
    this.htmlEl.addEventListener('click', this.watchBodyHandle)
    this.el.addEventListener('click', this.elPropagationHandle)
  }

  unWatchBody() {
    this.htmlEl.removeEventListener('click', this.watchBodyHandle)
    this.el.removeEventListener('click', this.elPropagationHandle)
  }
}