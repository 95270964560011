import 'babel-polyfill'
import 'custom-event-polyfill'
// .dataset polyfill
import 'element-dataset'
import {TweenLite} from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import IntroAnim from './anim/introAnim'
import Home from './modules/home'
import FindACentre from './modules/find-a-centre'
import Header from './modules/header'
import Footer from './modules/footer'
import Swiper from 'swiper'
import SectionExpander from './modules/section-expander'
import Expander from './modules/expander'
import RippleExpand from './modules/ripple-expand'
import jump from 'jump.js'
import VideoModal from './modules/video-modal'
import {getClosest} from './helpers/helpers'
import Tabs from './modules/tabs'
import TeamMember from './modules/team'
import Sliders from './modules/sliders'
import Tip from './modules/tip'
import Quiz from './modules/quiz'
import Convo from './modules/convo'
import Emergency from './modules/emergency'
import LiveChat from './modules/live-chat'
import FilterMenu from './modules/filter-menu'
import ResourceTheme from './modules/eservices/resource-theme'
import CommunityMap from './modules/eservices/community-map'
import eservicesPage from './modules/eservices/eservices-page'
import {isTouchDevice} from './helpers/helpers';
import Panic from './modules/panic'


class App {

  constructor (){
    document.querySelector('body').classList.add('js-enabled')

    // console.log('%cBuilt by', 'font: 200 16px Calibri;color:#CCC');
    // console.log('%cSignals', 'font: 200 28px Calibri;color:#93cb3c');
    // console.log('%chttp://www.signals.ca', 'font: 200 16px Calibri;color:#CCC');

    this.html = document.querySelector('html');
    this.body = document.querySelector('body');

    // Elements
    this.locationsMap = document.querySelector('.locations-map')
    this.sectionExpanders = Array.from(document.querySelectorAll('.section-expander'))
    this.expanders = Array.from(document.querySelectorAll('[data-expand]'))
    this.infoTooltips = Array.from(document.querySelectorAll('.info-tooltip'))
    this.scrollPrompt = document.querySelector('[scroll-prompt]')
    this.videoModalTrigger = document.querySelector('[video-modal]')
    this.tabs = Array.from(document.querySelectorAll('[data-tabs]'))
    this.teamMembers = Array.from(document.querySelectorAll('.team-member:not(.team-member--placeholder)'))
    this.resourceTheme = document.querySelector('.single-theme_type')
    this.bodyEl = document.querySelector('html')
    this.tips = Array.from(document.querySelectorAll('.tip'))
    this.quiz = document.querySelector('[data-quiz]')
    this.convo = document.querySelector('.home-convo')
    this.emergency = document.querySelector('.emergency-nav')
    this.liveChats = Array.from(document.querySelectorAll('.live-chat-trigger'))
    this.filterMenus = Array.from(document.querySelectorAll('.filters__menu'))
    this.communityMap = document.getElementById('community-map')
    this.headerContainerEl = document.querySelector('.site-header__container');
    this.ga = Array.from(document.querySelectorAll('[data-ga]'));
    this.links = Array.from(document.querySelectorAll('a'));

    this.eservicesPage = document.querySelector('.eservices__container');

    this.locationCardTemps = Array.from(document.querySelectorAll('.location-card-temp'))

    this.localLinks = Array.from(document.querySelectorAll('a[href^="#"]'))

    // if(document.querySelector('.home')){
    //   // Disabled for working. Also remove anim-complete class on body
    //   new Home()
    //   this.introAnim = new IntroAnim()
    // }

    this.init()
    this.hotFix()
  }

  init(){

    if (!this.body.classList.contains('clean-page')) {
      this.header = new Header()
      this.footer = new Footer()
    }

    const touchClass = isTouchDevice() ? 'touch' : 'no-touch';
    this.html.classList.add(touchClass);

    // Initialize quote slider
    this.sliders = new Sliders()

    if(this.locationsMap){
      new FindACentre()
    }

    if (this.communityMap) {
      this.mapEl = new CommunityMap(this.communityMap);
    }

    if(this.sectionExpanders){
      if (this.sectionExpanders) {
        this.sectionExpandersArray = []
        this.sectionExpanders.forEach(el => this.sectionExpandersArray.push(new SectionExpander(el)))
      }
    }

    if(this.infoTooltips){
      this.infoTooltips.forEach(el => new RippleExpand(el))
    }

    if(this.scrollPrompt){
      this.scrollPrompt.addEventListener('click', (e) => {
        e.preventDefault()
        const parentSection = getClosest(this.scrollPrompt, 'section')
        const sectionDimensions = parentSection.getBoundingClientRect()
        // Scroll to bottom of current section
        window.requestAnimationFrame(() => {
          let scrollTo = sectionDimensions.height - this.headerContainerEl.getBoundingClientRect().height;
          TweenLite.to(window, 1, { ease: Power2.easeInOut, scrollTo: { y: scrollTo } });
        });
      })
    }

    if(this.videoModalTrigger){
      new VideoModal(this.videoModalTrigger)
    }

    if(this.tabs){
      this.tabs.forEach(el => new Tabs(el))
    }

    if(this.teamMembers){
      this.teamMembers.forEach(el => new TeamMember(el))
    }

    if (this.expanders) {
      this.expanders.forEach(el => new Expander(el))
    }

    if (this.resourceTheme) {
      new ResourceTheme();
    }

    if(this.tips) {
      this.tips.forEach(tip => new Tip(tip))
    }

    if(this.quiz) {
      new Quiz(this.quiz)
    }

    if (this.convo) {
      new Convo(this.convo)
    }

    if (this.emergency) {
      new Emergency(this.emergency)
    }

    if (this.liveChats) {
      this.liveChats.forEach(chat => new LiveChat(chat))
    }

    if (this.filterMenus) {
      this.filterMenus.forEach(menu => new FilterMenu(menu))
    }

    if (this.eservicesPage) {
      new eservicesPage(this.eservicesPage);
    }

    if (this.ga) {
      this.ga.forEach(el => { this.gaTracking(el) });
    }
    new Panic();

    // TEMPORARY WHILST THERE'S NO LOCATION PAGE
    this.locationCardTemps.forEach(el => el.addEventListener('click', e => {
      el.classList.contains('show-card-info') ? el.classList.remove('show-card-info') : el.classList.add('show-card-info');
    }))

    // Testing for handheld device
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      document.querySelector('body').classList.add('handheld');
    }

    if (this.links) {
      const pdfLinks = this.links.filter(link => {
        let isPDF = false;
        if (link.href) {
          if (link.href.slice(-4) === '.pdf' || link.href.slice(-4) === '.PDF') {
            isPDF = true;
          }
        }
        return isPDF;
      });

      if (pdfLinks) {
        pdfLinks.forEach(link => {
          let fileName = link.href.substr(link.href.lastIndexOf('/') + 1).slice(0, -4);
          link.addEventListener('click', e => {
            //ga('send', 'event', 'File Download', 'download', fileName);
            //console.log(fileName)
          });
        });
      }
    }

    if (this.localLinks && this.localLinks.length) {
      this.localLinks.forEach(el => el.addEventListener('click', e => {
        e.preventDefault();
        TweenLite.to(window, 1, {scrollTo: el.getAttribute('href')});
      }))
    }

  }

  hotFix() {
    if (navigator.appVersion.indexOf("MSIE 10") !== -1) {
      this.bodyEl.classList.add('ie10')
      this.bodyEl.classList.add('ie-fixes')
    }
    const UAString = navigator.userAgent;
    if (UAString.indexOf("Trident") !== -1 && UAString.indexOf("rv:11") !== -1){
      this.bodyEl.classList.add('ie11')
      this.bodyEl.classList.add('ie-fixes')
    }
  }

  gaTracking(el) {
    const category = el.getAttribute('data-ga');
    const label = el.getAttribute('data-ga-label');

    if (category && label) {
      el.addEventListener('click', () => {
        //ga('send', 'event', category, 'click', label);
      });
    }
  }
}

window.App = new App();
