export default class Tip {
  constructor(tip) {
    this.tip = tip;

    this.trigger = this.tip.querySelector('[data-open-tip]')
    this.tipClosers = Array.from(this.tip.querySelectorAll('[data-close-tip]'))

    this.activeClass = 'active'
    
    this.tipIsOpen = false
    this.events()
  }

  events() {
    this.openTip = this.openTip.bind(this)
    this.closeTip = this.closeTip.bind(this)

    this.trigger.addEventListener('click', this.openTip)
    
    this.tipClosers.forEach(tipCloser => tipCloser.addEventListener('click', this.closeTip))
  }

  openTip() {
    this.tipIsOpen = true
    this.tip.classList.add(this.activeClass)
  }

  closeTip() {
    this.tipIsOpen = false
    this.tip.classList.remove(this.activeClass)
  }
}