export default class LiveChat {

	constructor(el) {
		this.el = el;
		this.id = this.el.getAttribute('data-id');
		this.url = this.el.getAttribute('data-url');

		this.events();
	}

	events() {
		this.el.addEventListener('click', this.clickHandle.bind(this));
	}

	clickHandle(e) {
		e.preventDefault();

		switch (this.id) {

			case 'youth-in-bc':
			this.openChat('chatWindow', 490, 550);
			break;

			case 'kids-help-phone':
			this.openChat('iceIM', 490, 760);
			break;
		}
	}

	// opens chat window
	openChat(name, width, height) {
		window.open(this.url, name, `width=${width}, height=${height}, scrollbars=1, menubar=0, resizable=1, status=0`);
	}
}