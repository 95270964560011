// TODO: watch for centre's clicked and trigger markerClick. Closing centres and
// triggering maps: google.maps.event.trigger(markers[i], 'click');
export default class FindACentre {
  constructor(){
    this.bodyEl = document.querySelector('body')
    this.centresTrigger = document.querySelector('.map-trigger__centres')
    this.mapTrigger = document.querySelector('.map-trigger__map')

    this.locationTriggers = Array.from(document.querySelectorAll('[data-map-index]'))

    this.events()

    this.centresOpen = false;
  }

  // Bind to UI events
  events(){
    this.centresTrigger.addEventListener('click', this.centresClick.bind(this))
    this.mapTrigger.addEventListener('click', this.mapClick.bind(this))

    this.locationTriggers.forEach(el => el.addEventListener('click', this.selectLocation.bind(this, el)))
  }

  centresClick(e){
    this.centresOpen ? this.closeCentres() : this.openCentres()
  }

  mapClick(){
    this.closeCentres()
  }

  openCentres(){
    this.centresOpen = true
    this.bodyEl.classList.add('show-centres')
  }

  closeCentres(){
    this.centresOpen = false
    this.bodyEl.classList.remove('show-centres')
  }

  // Location has been clicked
  selectLocation(el){
    const index = el.getAttribute('data-map-index')
    // Globals from the page this would be launched on
    map.panTo(markers[index].getPosition())
    google.maps.event.trigger(markers[index], 'click');
    this.closeCentres()
  }

}