export default class TeamMember {
  constructor(el){
    this.el = el;
    this.htmlEl = document.querySelector('html')
    this.tile = this.el.querySelector('.team-member__tile');
    this.popup = this.el.querySelector('.team-member__popup');
    this.closeBtn = this.el.querySelector('.team-member__popup__close');

    this.events();
  }

  events () {

    this.tile.addEventListener('click', this.open.bind(this));

    this.closeBtn.addEventListener('click', this.close.bind(this));

    this.popup.addEventListener('click', e => {
      if (e.target === this.popup) {
        this.close();
      }
    });
  }

  // Close popup
  close () {
    this.popup.classList.remove('open');
  }

  // Open popup
  open () {
    this.popup.classList.add('open');
  }

}