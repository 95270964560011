export default class Header {

  constructor(){
    if (document.querySelector('.panic-popup')) {
      this.keyStroke = [],
      this.keyCode = 27,
      this.safeWebsite = "https://www.theweathernetwork.com/ca";
      window.addEventListener('keydown', this.keyCaptureKey.bind(this, window));
      window.addEventListener('load', this.messageBarSize.bind(this, window));
      window.addEventListener('resize', this.messageBarSize.bind(this, window));
      this.panicLinks = document.querySelectorAll('button.panic');
      for (var i = 0; i < this.panicLinks.length; i++) {
        this.panicLinks[i].addEventListener('click', this.panic.bind(this));
      }
      if (document.querySelector('.close-panic-popup')) {
        document.querySelector('.close-panic-popup').addEventListener('click', this.closePanicPopup.bind(this));
      }
      var showPanicBox = window.localStorage.getItem("foundryMessageShown");
      if (! showPanicBox || showPanicBox < (Math.floor((new Date().getTime()) / 1000) - (60 * 6 * 24))) {
        document.querySelector('.panic-popup').classList.add('visible');
      }
    }
  }

  messageBarSize(el, e) {
    let headerBar = document.querySelector('header.site-header').getBoundingClientRect().height;
    let logoOffset = document.querySelector('.panic-bar-top').getBoundingClientRect().height + document.querySelector('.site-header__content').getBoundingClientRect().height;
    document.querySelector('.site-nav-drawer').style.top = logoOffset + 'px';
    document.querySelector('header.eservices-header').style.paddingTop = headerBar + 'px';
  }


  detectIE() {
    var ua = window.navigator.userAgent, msie = ua.indexOf("MSIE ");
    if (0 < msie)
        return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    if (0 < ua.indexOf("Trident/")) {
        var rv = ua.indexOf("rv:");
        return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10)
    }
    var edge = ua.indexOf("Edge/");
    return 0 < edge && parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10)
  }

  panic(e) {
    e.preventDefault();
    document.body.innerHTML = "";
    window.history.replaceState({}, "", "/");
    if (this.detectIE()) {
      window.open(safeWebsite);
      window.open("", "_self", "");
      window.close();
    } else {
      window.location.assign(this.safeWebsite);
    }
  }

  closePanicPopup(e) {
    document.querySelector('.panic-popup').classList.remove('visible');
    window.localStorage.setItem("foundryMessageShown", Math.floor((new Date().getTime()) / 1000));
  }

  keyCaptureKey(el,e) {
    if (e.keyCode !== this.keyCode) {
      return;
    }
    this.keyStroke.push(e.keyCode);
    if (this.keyStroke.length >= 2) {
      e.preventDefault();
      this.panic(e);
    }
  }

}
