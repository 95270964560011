import Swiper from 'swiper'

export default class Sliders {
  constructor(){
    this.quoteSliders = Array.from(document.querySelectorAll('.quotes'))
    this.cardSlider = Array.from(document.querySelectorAll('[data-slider]'))
    this.infoSliders = Array.from(document.querySelectorAll('.info-slider'))
    this.newsSlider = Array.from(document.querySelectorAll('.news'))
    // this.tipSliders = Array.from(document.querySelectorAll('.tip-slider'))
    this.homeSlider = document.querySelector('.home-slider')
    this.mediaStories = document.querySelector('.story-slider .story-slider__container')

    this.storySlider = document.querySelector('.story-slider-extra');
    this.headerStorySlider = document.querySelector('.header-stories');
    this.pathfinderQuestionnaire = document.querySelector('.pfq-slider');

    this.launchSliders()
  }

  launchSliders(){

    if (this.quoteSliders) {
      this.quoteSliders.forEach((el, i) => {
        const nextBtn = el.querySelector('.slider__pager--next').classList.add('slider__pager--next-' + i);
        const prevBtn = el.querySelector('.slider__pager--prev').classList.add('slider__pager--prev-' + i);
        const pagination = el.querySelector('.slider__pagination').classList.add('slider__pagination-quote' + i);

        new Swiper(el.querySelector('.quote-slider'), {
          virtualTranslate: true,
          loop: true,
          pagination: '.slider__pagination-quote' + i,
          nextButton: '.slider__pager--next-' + i,
          prevButton: '.slider__pager--prev-' + i,
          wrapperClass: 'slide-container',
          slideClass: 'slide',
          slideActiveClass: 'slide-active',
          autoHeight: true,
          paginationClickable: true,
          bulletActiveClass: 'active',
          slidesPerView: 1,
          onInit: this.sliderInit,
        })
      })
    }

    // Initialize card slider
    if (this.cardSlider) {
      this.cardSlider.forEach((el, i) => {
        const nextBtn = el.querySelector('.slider__pager--next').classList.add('slider__pager--next-0' + i);
        const prevBtn = el.querySelector('.slider__pager--prev').classList.add('slider__pager--prev-0' + i);
        const pagination = el.querySelector('.slider__pagination').classList.add('slider__pagination-0' + i);
        const slideCount = el.querySelectorAll('.card-slide').length;
        new Swiper(el.querySelector('.card-slider'), {
          loop: false,
          pagination: (slideCount > 3 ? '.slider__pagination-0' + i : ''),
          nextButton: '.slider__pager--next-0' + i,
          prevButton: '.slider__pager--prev-0' + i,
          wrapperClass: 'slide-container',
          slideClass: 'card-slide',
          slideActiveClass: 'card-active',
          autoHeight: false,
          paginationClickable: true,
          bulletActiveClass: 'active',
          slidesPerView: 3,
          keyboardControl: true,
          onInit: this.sliderInit,
          spaceBetween: 40,
          breakpoints: {
            600: {
              slidesPerView: 1,
              spaceBetween: 10
            },
            900: {
              slidesPerView: 2,
              spaceBetween: 40
            }
          },
          roundLengths: true
        })
      })
    }

     if (this.infoSliders) {
       this.infoSliders.forEach((infoSlider, index) => {

        const pagination = infoSlider.querySelector('.slider__pagination');
        pagination.classList.add('slider__pagination-info' + index);
        
        new Swiper(infoSlider.querySelector('.slider'), {
          virtualTranslate: true,
          loop: false,
          pagination: '.slider__pagination-info' + index,
          wrapperClass: 'slider__container',
          slideClass: 'slider__slide',
          slideActiveClass: 'slide-active',
          autoHeight: true,
          paginationClickable: true,
          bulletActiveClass: 'active',
          keyboardControl: true,
          onInit: this.sliderInit,
        })
      })
    }

    if(this.newsSlider) {
      this.newsSlider.forEach((news, index) => {
        const nextBtn = news.querySelector('.slider__pager--next').classList.add('slider__pager--next-' + index);
        const prevBtn = news.querySelector('.slider__pager--prev').classList.add('slider__pager--prev-' + index);

        new Swiper(news.querySelector('.slider'), {
          // virtualTranslate: true,
          loop: false,
          pagination: '.slider__pagination-' + index,
          nextButton: '.slider__pager--next-' + index,
          prevButton: '.slider__pager--prev-' + index,
          wrapperClass: 'slider__container',
          slideClass: 'slider__slide',
          slideActiveClass: 'slide-active',
          // autoHeight: true,
          slidesPerView: 3,
          onInit: this.sliderInit,
          // spaceBetween: 40,
          paginationClickable: true,
          bulletActiveClass: 'active',
          keyboardControl: true,
          breakpoints: {
            600: {
              slidesPerView: 1,
              spaceBetween: 10
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40
            }
          }
        })
      })
    }

    if ( this.storySlider ) {

      new Swiper(this.storySlider.querySelector('.story-slider-extra__slider'), {
        // loop: false,
        pagination: '.story-slider-extra__pagination',
        nextButton: '.slider__pager--next',
        prevButton: '.slider__pager--prev',
        wrapperClass: 'story-slider-extra__slides',
        slideClass: 'story-slider-extra__slide',
        slideActiveClass: 'slide-active',
        onInit: this.sliderInit,
        paginationClickable: true,
        bulletActiveClass: 'active',
        keyboardControl: true,
        slidesPerView: 3,
        spaceBetween: 10,
        breakpoints: {
          600: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 10
          }
        }
      });
    }

    if ( this.headerStorySlider ) {

      new Swiper(this.headerStorySlider.querySelector('.slider'), {
        // loop: true,
        autoplay: 8000,
        speed: 800,
        pagination: '.header-stories__pagination',
        wrapperClass: 'header-stories__slides',
        slideClass: 'header-stories__slide',
        slideActiveClass: 'slide-active',
        onInit: this.sliderInit,
        paginationClickable: true,
        bulletActiveClass: 'active',
        keyboardControl: true,
        slidesPerView: 1,
        spaceBetween: 10
      });
    }

    // if (this.tipSliders) {
    //   this.tipSliders.forEach((tipSlider, index) => {
    //     const nextBtn = tipSlider.querySelector('.slider__pager--next').classList.add('slider__pager--next-' + index);
    //     const prevBtn = tipSlider.querySelector('.slider__pager--prev').classList.add('slider__pager--prev-' + index);

    //     const pagination = tipSlider.querySelector('.slider__pagination');
    //     pagination.classList.add('slider__pagination-info' + index);
        
    //     new Swiper(tipSlider.querySelector('.slider'), {
    //       virtualTranslate: true,
    //       loop: false,
    //       nextButton: '.slider__pager--next-' + index,
    //       prevButton: '.slider__pager--prev-' + index,
    //       pagination: '.slider__pagination-info' + index,
    //       wrapperClass: 'slider__container',
    //       slideClass: 'slider__slide',
    //       slideActiveClass: 'slide-active',
    //       autoHeight: true,
    //       paginationClickable: true,
    //       bulletActiveClass: 'active',
    //       keyboardControl: true,
    //       onInit: this.sliderInit,
    //     })
    //   })
    // }

    if(this.homeSlider){
      new Swiper(this.homeSlider.querySelector('.slider'), {
        virtualTranslate: true,
        loop: true,
        autoplay: 5000,
        // effect: 'fade',
        pagination: '.slider__pagination',
        nextButton: '.slider__pager--next',
        prevButton: '.slider__pager--prev',
        wrapperClass: 'slider__container',
        slideClass: 'slider__slide',
        slideActiveClass: 'slide-active',
        paginationClickable: true,
        bulletActiveClass: 'active',
        keyboardControl: true,
        onInit: this.sliderInit,
      })
    }

    if(this.mediaStories){
      new Swiper(this.mediaStories, {
        pagination: '.slider__pagination',
        nextButton: '.slider__pager--next',
        prevButton: '.slider__pager--prev',
        wrapperClass: 'slide-container',
        slideClass: 'slider-slide',
        slideActiveClass: 'slide-active',
        paginationClickable: true,
        bulletActiveClass: 'active',
        keyboardControl: true,
        onInit: this.sliderInit
      })
    }
    
    // Initialize pathfinder Questionnaire slider
    if (this.pathfinderQuestionnaire) {
      var pathfinderQuestionnaireSlider = new Swiper(this.pathfinderQuestionnaire, {
        pagination: '.pfq__pagination',
        nextButton: '.slider__pager--next',
        prevButton: '.slider__pager--prev',
        wrapperClass: 'pfq-container',
        slideClass: 'pfq-slide',
        slideActiveClass: 'slide-active',
        paginationClickable: true,
        bulletActiveClass: 'active',
        keyboardControl: true,
        onInit: this.sliderInit
      });
      
      //console.log(pathfinderQuestionnaireSlider);
      
      // Listen for the event.
      document.addEventListener('destroyQslider', function (e) {
        pathfinderQuestionnaireSlider.destroy( true, true );
      });
      document.addEventListener('updateQslider', function (e) {
        pathfinderQuestionnaireSlider.onResize(); // have to use onResize() because .update() doesn't update everything (classes, pagination)
      });
    }
  }

  sliderInit(swiper) {
    swiper.container[0].classList.add('show-slider');
  }

  isTablet(){
    return window.matchMedia('(max-width: 768px)').matches
  }

  isMobile(){
    return window.matchMedia('(max-width: 600px)').matches
  }
}