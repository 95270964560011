export default class FilterMenu {

	constructor(el) {
		this.el = el;
		this.select = this.el.querySelector('.filters__menu__select');
		this.dropdown = this.el.querySelector('.filters__menu__dropdown');
		this.body = document.querySelector('body');
		this.content = document.querySelector('.stories-articles');

		this.menuItems = [...this.dropdown.querySelectorAll('a')];

		this.events();
	}

	events() {
		this.select.addEventListener('click', this.toggleDropdown.bind(this));

		this.body.addEventListener('click', e => {
			if ( this.select != e.target ) {
				this.closeDropdown();
			}
		});

		this.menuItems.forEach(el => {
			el.addEventListener('click', this.disableContent.bind(this));
		});
	}

	toggleDropdown() {
		this.dropdown.classList.contains('active') ? this.closeDropdown() : this.openDropdown();
	}

	openDropdown() {
		this.dropdown.classList.add('active');
	}

	closeDropdown() {
		this.dropdown.classList.remove('active');
	}

	disableContent() {
		this.content.classList.add('disabled');
	}
}