import { getClosest } from '../../helpers/helpers'
import jump from '../../helpers/jump'
import throttle from 'lodash.throttle'



export default class ResourceTheme {
  constructor() {
    // Gettin' those elements
    this.htmlEl = document.documentElement || document.querySelector('html')
    this.bodyEl = document.querySelector('body')
    this.resourceTriggers = Array.from(document.querySelectorAll('[data-resource]'))
    this.returnTrigger = document.querySelector('.internal-nav-title a')
    this.activeClass = 'active'
    // this.learnMoreScroll = Array.from(document.querySelectorAll('.resource-panel__learn-more'))
    this.headerEl = document.querySelector('.site-header')
    this.quizzes = document.querySelector('.resource-theme__quizzes')

    this.title = document.querySelector('.eservices-header__title').textContent

    this.activePanel = null;

    this.events()
    // this.checkForHash()

    this.addPaddingForFooter()
  }

  // Handles all watchers
  events() {
    // Watch for panel trigger clicks
    // this.resourceTriggers.forEach(el => el.addEventListener('click', this.parseTrigger.bind(this, el)))
    // Watch for return panel click
    this.closePanel = this.closePanel.bind(this)
    if ( this.returnTrigger ) {
      this.returnTrigger.addEventListener('click', this.closePanel)
    }
    // this.learnMoreScroll.forEach(el => el.addEventListener('click', this.scrollResource.bind(this, el)))

    this.resourceScrollWatch = throttle(this.panelScrollHandler, 100).bind(this)

    window.addEventListener('resize', this.resizeHandle.bind(this));
  }

  // checkForHash() {
  //   // if has a hash that equals an element, launch that
  //   const targetID = window.location.hash.replace('#', '')
  //   const targetEl = document.getElementById(targetID)
  //   if (targetEl) {
  //     this.openPanel(targetEl)
  //   }
  // }

  // Scroll jumping for learn-more on resource panel page (apps, stories)
  // scrollResource(el) {
  //   const resourcePanel = getClosest(el, '.resource-panel')
  //   const offset = el.getBoundingClientRect().top
  //   this.bodyEl.classList.add('scrolled')
  //   jump(el, {
  //     container: resourcePanel
  //   })
  // }

  // // Listener for opening panels (apps, stories)
  // parseTrigger(el, e) {
  //   e.preventDefault()
  //   const targetID = el.getAttribute('data-resource')
  //   const targetEl = document.getElementById(targetID)
  //   const targetHref = el.getAttribute('href');
  //   if(targetEl){
  //     this.openPanel(targetEl)
  //     history.pushState(null, '', targetHref);
  //   }
  // }

  // Opens the resource overlay panel
  openPanel(el) {
    this.changeHeaderReturn()
    this.activePanel = el
    this.htmlEl.classList.add('resource-panel-active')
    this.activePanel.addEventListener('scroll', this.resourceScrollWatch)

    el.classList.add(this.activeClass)
  }

  // watch the scroll panel and handle nav state
  panelScrollHandler(e) {
    let scrolled = this.activePanel.scrollTop
    if(scrolled > 0){
      this.bodyEl.classList.add('scrolled')
    }else{
      this.bodyEl.classList.remove('scrolled')
    }
  }

  // Close overlay panel
  closePanel(e) {
    if(!this.activePanel) return;
    e.preventDefault()
    history.pushState(null, '', window.location.href.replace(/\#(.+)/, '').replace(/http(s?)\:\/\/([^\/]+)/, '') );
    this.resetHeaderReturn()
    this.htmlEl.classList.remove('resource-panel-active')
    this.activePanel.removeEventListener('scroll', this.resourceScrollWatch)
    this.activePanel.classList.remove(this.activeClass)
    this.activePanel = null
    // Allow the header.js scroll event to fire to make sure the nav is correct
    window.dispatchEvent( new Event('scroll') );
  }

  // Store the current back nav element
  changeHeaderReturn() {
    const returnTriggerSpan = this.returnTrigger.querySelector('span');
    this.storeText = returnTriggerSpan.textContent
    returnTriggerSpan.textContent = this.title
  }
  
  // Change the header text back to previously stored value when leaving a resource overlay panel
  resetHeaderReturn() {
    const returnTriggerSpan = this.returnTrigger.querySelector('span');
    returnTriggerSpan.textContent = this.storeText
  }

  // Used for adding the padding to show the footer with the sticky quizzes on top
  addPaddingForFooter() {
    // If on breakpoint and there are quizzes
    if ( this.quizzes ) {
      this.bodyEl.style.paddingBottom = window.matchMedia('(max-width: 600px)').matches ?` ${this.quizzes.getBoundingClientRect().height}px` : '0px';
    }
  }

  resizeHandle() {
    this.addPaddingForFooter();
  }
}