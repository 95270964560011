export default class VideoModal {
  constructor(trigger){
    this.trigger = trigger
    this.bodyEl = document.querySelector('body')
    this.overlay = document.getElementById('video-modal')
    this.open = false
    this.closers = Array.from(document.querySelectorAll('[video-modal-close]'))

    this.events()
  }

  events(){
    this.trigger.addEventListener('click', this.openModal.bind(this))

    this.closers.forEach(el => el.addEventListener('click', this.closeModal.bind(this)))
  }

  openModal(){
    this.open = true
    this.overlay.classList.add('active')
    this.bodyEl.classList.add('no-scroll')
  }

  closeModal(){
    this.open = false
    this.overlay.classList.remove('active')
    this.bodyEl.classList.remove('no-scroll')
  }
}