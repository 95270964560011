// import jump from 'jump.js'
// import jump from '../helpers/jump'
import {getClosest, getPosition, pageHeight, viewport} from '../helpers/helpers';
import {TweenLite} from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

export default class Quiz {

  // Setup elements that'll be used and any defaults
  constructor(quiz){
    this.quiz = quiz;
    this.quizName = quiz.getAttribute('data-quiz-name');
    this.threshold = {
      high: this.quiz.getAttribute('data-quiz-high'),
      mid: this.quiz.getAttribute('data-quiz-mid'),
      low: this.quiz.getAttribute('data-quiz-low')
    };

    this.panels = [...document.querySelectorAll('.quiz-panel')];
    this.pagination = this.quiz.querySelector('.quiz-pagination');
    this.paginationButtons = [...document.querySelectorAll('.quiz-pagination__bullet')];
    this.resultSections = [...this.quiz.querySelectorAll('[data-quiz-result]')];

    this.curIndex = 0
    this.activePanel = this.panels[this.curIndex]
    this.activeClass = 'active'

    this.navBtns = [...this.quiz.querySelectorAll('[data-target-panel]')];
    this.answerBtns = [...this.quiz.querySelectorAll('.answer-btn')];
    this.questionPanels = [...this.quiz.querySelectorAll('.quiz-panel.question')];

    // this.answersContainers = [...this.quiz.querySelectorAll('.question__answers')];

    this.header = document.querySelector('.site-header__container');

    // this.init();
    this.events();
  }

  // init() {

  //   this.answersContainers.forEach(el => {
  //     // Create a 'Prefer not to answer' button for each answers container
  //     const skipBtn = document.createElement('div');
  //     skipBtn.classList.add('answer-btn');
  //     const skipText = 'Prefer not to answer';
  //     skipBtn.innerHTML = `<span>${skipText}</span>`;

  //     skipBtn.addEventListener('click', () => {
  //       const parentPanel = getClosest(el, '.quiz-panel');
  //       this.resetPanel(parentPanel);
  //       this.navHandle(this.curIndex+1);
  //     });

  //     el.appendChild(skipBtn);
  //   });
  // }

  events() {
    this.genderFormHandle = this.genderFormHandle.bind(this);

    this.navBtns.forEach(el => {
      el.addEventListener('click', this.navBtnHandle.bind(this, el));
    });

    this.answerBtns.forEach(el => {
      el.addEventListener('click', this.answerBtnHandle.bind(this, el));
    });
  }

  resetPanel(panel) {
    // Set panel score to 0
    panel.setAttribute('data-score', 0);
    panel.removeAttribute('data-value');

    if ( panel.classList.contains('question') && !this.isGenderOrAgePanel(panel) ) {
      panel.removeAttribute('data-answer-index');
    }

    const answers = [...panel.querySelectorAll('.answer-btn')];
    answers.forEach(a => {
      this.resetAnswerBtn(a);
    });

    if ( panel.hasAttribute('data-has-fu') ) {
      const questions = [...panel.querySelectorAll('.subq')];

      questions.forEach((q,i) => {
        q.removeAttribute('data-score');
        q.removeAttribute('data-answer-index');

        if (i > 0) {
          q.classList.remove('active');
        }
      });
    }
  }

  // Navigation button click handler
  navBtnHandle(el) {
    const target = parseInt(el.getAttribute('data-target-panel'));

    // Skip button
    // if ( el.classList.contains('quiz-footer__btn--forward') ) {
    //   const parentPanel = getClosest(el, '.quiz-panel');
    //   this.resetPanel(parentPanel);
    // }

    this.navHandle(target);

    // GA tracking: quiz begin
    if ( el.hasAttribute('data-begin-quiz-btn')){// && this.gaValid() ) {
      this.quizBeginTracking();
    }
  }

  resetAnswerBtn(el) {
    el.classList.remove('selected');
    if ( el.hasAttribute('data-open-ended') ) {
      let form = el.querySelector('.open-ended-form');
      form.classList.remove('active');
      form.removeEventListener('submit', this.genderFormHandle);

      const input = form.elements.namedItem('gender');
      input.value = '';
    }
  }

  // Answer button click handler
  answerBtnHandle(el) {
    const value = el.getAttribute('data-score');
    const parentPanel = getClosest(el, '.quiz-panel');
    const aIndex = el.getAttribute('data-index');
    const aVal = el.getAttribute('data-value');
    const question = getClosest(el, '.quiz-panel__container');
    const answers = [...question.querySelectorAll('.answer-btn')];

    answers.forEach(a => {
      if ( a == el ) {
        a.classList.add('selected');
      } else {
        this.resetAnswerBtn(a);
      }
    });

    // Check to see if this panel contains follow-up question(s)
    if ( parentPanel.hasAttribute('data-has-fu') ) {
      const questions = [...parentPanel.querySelectorAll('.subq')];
      const qIndex = parseInt(question.getAttribute('data-q-index'));

      question.setAttribute('data-score', parseInt(value));
      question.setAttribute('data-answer-index', aIndex);

      // Check to see if question has a follow-up question
      if ( question.hasAttribute('data-pre-req') ) {
        const req = question.getAttribute('data-pre-req');

        // If answer matches the pre-req, display the follow-up question
        if ( req == aIndex ) {
          this.showFollowUp(questions, parentPanel, qIndex);
        } else {
          // Otherwise, calculate value and go to the next question
          this.followUpHandle(questions, parentPanel, qIndex);
        }

      } else {
        // If it does not, it means it is the end of this panel. Calculate value and go to the next one
        this.followUpHandle(questions, parentPanel, qIndex);
      }

    } else {

      if ( el.hasAttribute('data-open-ended') ) {
        // Open ended question
        const form = el.querySelector('.open-ended-form');
        form.classList.add('active');
        form.addEventListener('submit', this.genderFormHandle);
      } else {
        // Regular question
        parentPanel.setAttribute('data-score', parseInt(value));
        this.setPanelValue(parentPanel, aVal, aIndex);
        this.navHandle(this.curIndex+1);
      }
    }
  }

  setPanelValue(panel, aVal = 'na', aIndex = 0) {
    if ( this.isGenderOrAgePanel(panel) ) {
      panel.setAttribute('data-value', aVal);
    } else {
      panel.setAttribute('data-answer-index', aIndex);
    }
  }

  isGenderOrAgePanel(panel) {
    const index = parseInt(panel.getAttribute('data-quiz-panel'));
    // Panel 0 = intro; Panel 1 = gender; Panel 2 = age
    return index === 1 || index === 2;
  }

  showFollowUp(questions, parentPanel, qIndex) {
    questions.forEach((q,i) => {
      if ( i <= qIndex+1 ) {
        q.classList.add('active');
      } else {
        q.classList.remove('active');
        q.removeAttribute('data-score');
        q.removeAttribute('data-answer-index');
      }
    });

    window.requestAnimationFrame(() => {
      const targetQuestion = parentPanel.querySelector(`[data-q-index="${qIndex+1}"]`);
      const y = getPosition(targetQuestion).y - this.header.getBoundingClientRect().height;
      const scrollTo = Math.min(y, pageHeight() - viewport().height);
      TweenLite.to(window, 0.6, { ease: Power2.easeInOut, scrollTo: { y: scrollTo } });
    });
  }

  followUpHandle(questions, parentPanel, qIndex) {
    let answerIndices = '';

    questions.forEach((q,i) => {
      if ( i > qIndex ) {
        q.classList.remove('active');
        q.removeAttribute('data-score');
        q.removeAttribute('data-answer-index');
      } else {
        if ( answerIndices !== '' ) {
          answerIndices += `->${q.getAttribute('data-answer-index')}`;
        } else {
          answerIndices = q.getAttribute('data-answer-index');
        }
      }
    });

    let total = questions.reduce((val,q) => {
      let add = q.hasAttribute('data-score') ? parseInt(q.getAttribute('data-score')) : 0;
      return val + add;
    }, 0);

    parentPanel.setAttribute('data-score', total);
    this.setPanelValue(parentPanel, 'na', answerIndices);
    this.navHandle(this.curIndex+1);
  }

  genderFormHandle(e) {
    e.preventDefault();
    const input = e.target.elements.namedItem('gender');
    const val = input.value.trim();
    const parentPanel = getClosest(input, '.quiz-panel');

    input.value = val;

    if (val) {
      parentPanel.setAttribute('data-value', val);
    } else {
      parentPanel.setAttribute('data-value', 'Prefer not to answer')
    }

    this.navHandle(this.curIndex+1);
  }

  // Go to the targetted index
  navHandle(target) {
    if ( target != this.curIndex && target >= 0 && target < this.panels.length ) {
      this.activePanel.classList.remove(this.activeClass);
      this.curIndex = target;
      this.activePanel = this.panels[this.curIndex];
      this.activePanel.classList.add(this.activeClass);

      if ( this.activePanel.classList.contains('question') ) {
        this.resetPanel(this.activePanel);
      }

      // Calculate final results
      if ( this.activePanel.classList.contains('quiz-results') ) {
        this.resultHandle();
      }

      // Pagination bullets
      if ( target != 0 && target != this.panels.length-1 ) {
        this.paginationHandle();
        this.quiz.classList.add('show-pagination');

        window.requestAnimationFrame(() => {
          let y = getPosition(this.pagination).y - this.header.getBoundingClientRect().height;
          TweenLite.to(window, 0.6, { ease: Power2.easeInOut, scrollTo: { y: y } });
        });
      } else {
        this.quiz.classList.remove('show-pagination');
        TweenLite.to(window, 0.6, { ease: Power2.easeInOut, scrollTo: { y: 0 } });
      }

    } else {
      console.log('Something went wrong');
    }
  }

  paginationHandle() {
    this.paginationButtons.forEach((button, i) => {
      if ( i < this.curIndex-1 ) {
        button.classList.add('active');
      } else {
        button.classList.remove('active');
      }
    });
  }

  resultHandle() {
    const score = this.questionPanels.reduce((val, q) => {
      return val + parseInt(q.getAttribute('data-score'));
    }, 0);

    if ( score <= this.threshold.low ) {
      // console.log('low result: ', this.threshold, score);
      this.showResults('low');
    } else if(score <= this.threshold.mid) {
      // console.log('mid result: ', this.threshold, score);
      this.showResults('mid');
    } else if(score <= this.threshold.high) {
      // console.log('high result: ', this.threshold, score);
      this.showResults('high');
    } else {
      // console.log('extreme result: ', this.threshold, score);
      this.showResults('extreme');
    }

    //if ( this.gaValid() ) {
      this.quizCompleteTracking(score);
    //}
  }

  quizBeginTracking() {
    //ga('send', 'event', 'Quizzes', 'Quiz Begin', this.quizName);
    dataLayer.push({
	'event': 'quizAct',
	'eventAction': 'Quiz Begin',
	'eventLabel': this.quizName
	});

  }

  /*
  dimension1 : Gender
  dimension2 : Age
  dimension3 : Severity Score
  dimension4 : Question Index
  dimension5 : Answer Index
  */

  quizCompleteTracking(score) {
    const gender = this.getGender();
    const age = this.getAge();

    /*ga('send', 'event', 'Quizzes', 'Quiz Complete', this.quizName, {
      'dimension1': gender,
      'dimension2': age,
      'dimension3': score
    });*/
    App.quiz.gender = gender;
    App.quiz.age = age;
    dataLayer.push({
	'event': 'quizAct',
	'eventAction': 'Quiz Complete',
	'eventLabel': this.quizName
	});
	dataLayer.push({
	'event': 'quizResult',
	'eventAction': score,
	'eventLabel': this.quizName
	});


    // Track entire quiz (questions other than gender/age)
    this.questionPanels.forEach(panel => {
      if ( !this.isGenderOrAgePanel(panel) ) {
        let qIndex = this.getQuestionIndex(panel);
        let aIndex = this.getAnswerIndex(panel);

        /*ga('send', 'event', 'Quizzes', 'Quiz Result', this.quizName, {
          'dimension1': gender,
          'dimension2': age,
          'dimension4': qIndex,
          'dimension5': aIndex
        });*/
      }
    });
  }

  getQuestionIndex(panel) {
    return parseInt(panel.getAttribute('data-quiz-panel'));
  }

  getAnswerIndex(panel) {
    const aIndex = panel.getAttribute('data-answer-index');
    return aIndex ? aIndex : 'Prefer not to answer';
  }

  getGender() {
    const gender = this.panels[1].getAttribute('data-value');
    return gender ? gender : 'Prefer not to answer';
  }

  getAge() {
    const age = this.panels[2].getAttribute('data-value');
    return age ? age : 'Prefer not to answer';
  }

  // Picking the correct results to show based on the answers
  showResults(result) {

    this.resultSections.forEach(resultSection => {
      if ( resultSection.getAttribute('data-quiz-result') == result ) {
        resultSection.classList.add('active');
      } else {
        resultSection.classList.remove('active');
      }
    });
  }

  gaValid() {
    return typeof ga === 'function' && this.quizName;
  }

}