import animationData from './animationData'
// import bodymovin from './bodymovin'
// var bodymovin = require('./bodymovin.js')

const URL = '/app/themes/foundry/assets/js/anim/data.json'

export default class Intro {

  constructor(){
    this.animEl = document.getElementById('animation')
    this.bodyEl = document.querySelector('body')
    this.greenWiper = document.getElementById('green-wiper')
    this.skipEl = document.getElementById('skip-intro')

    // A reference for the second play with the bind so the event can be removed
    this.animParams = {
        container: this.animEl,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: URL,
        // animationData: animationData,
        rendererSettings: {
          // preserveAspectRatio: 'xMidYMax slice',
          scaleMode: 'fit'
        }
    }

    this.playSecondSegmentHandle = this.playSecondSegment.bind(this);
    this.start()
  }

  start(){
    this.anim = bodymovin.loadAnimation(this.animParams);
    this.enableSkip()
    
    // Play first segment upto wipe
    this.anim.addEventListener('DOMLoaded', ()=>{
      this.anim.addEventListener('complete', this.playSecondSegmentHandle)

      // console.log('Start animation');
      this.anim.playSegments([0,110], true);
    })
  }

  playSecondSegment(e){
    this.greenWipe()

    // console.log('Play second segment')
    // Remove eventlistener to avoid triggering second segment infinitely
    this.anim.removeEventListener('complete', this.playSecondSegmentHandle);

    setTimeout(()=>{
      this.anim.playSegments([121,720], true);

      this.anim.addEventListener('complete', (e)=>{
        this.endAnim()
      });

    }, 400)
  }


  greenWipe(){
    this.greenWiper.classList.add('left-to-right')
    // Double timeout to handle the timing with the swipe
    setTimeout(()=>{
        this.greenWiper.classList.remove('left-to-right')
    }, 1300);

  }

  endAnim(){
    // console.log('End animation');
    this.anim.destroy()
    this.bodyEl.classList.add('anim-complete')
  }

  enableSkip(){
    this.skipEl.addEventListener('click', e => {
      this.endAnim()
    })
  }
    

}