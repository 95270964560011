import { TimelineMax } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import throttle from 'lodash.throttle';
import {getClosest, viewport, getPosition, pageHeight, scrollTop} from '../helpers/helpers';

export default class Convo {

  constructor(convo) {
    this.wrapper = convo;
    this.back = [...document.querySelectorAll('.home-convo__back-btn')];
    this.header = document.getElementById('site-header');
    this.questions = [...document.querySelectorAll('.home-convo__q-container')];
    this.firstGroup = document.querySelector('.home-convo__group');

    this.events();

    // Do an inital load as the page may already be scrolled on a refresh
    this.scrollHandle();

  }

  events() {

    // Throttled scroll (1000/60 = 16) 60 fps
    this.scrollHandle = throttle(this.scrollHandle, 16).bind(this);
    window.addEventListener('scroll', this.scrollHandle);

    // go to the next level or a new page if url exists
    this.questions.forEach(el => {
      let group = getClosest(el, '.home-convo__group');
      let url = el.dataset.href;
      el.addEventListener('click', e => {
        e.preventDefault();
        if ( url ) {
          window.location.href = url;
        } else {
          let target = getClosest(el, '.home-convo__q').nextElementSibling;
          this.nagivate(group, target);
        }
      });
    });

    // go back a level
    this.back.forEach(el => {
      let group = getClosest(el, '.home-convo__group');
      let parent = group.parentElement;
      el.addEventListener('click', this.nagivate.bind(this, group, parent));
    });

  }

  scrollHandle() {
    if (!this.wrapper.classList.contains('in-view')) {
      let heightInView = viewport().height + scrollTop() - getPosition(this.firstGroup).y;
      if ( heightInView >= 0 ) {
        this.wrapper.classList.add('in-view');
        let firstLevelQuestions = [...this.firstGroup.querySelectorAll('[data-level="1"]')];

        let inViewAnimation = new TimelineMax({
          delay: 0.3,
          onStart: () => { this.wrapper.classList.add('no-action'); },
          onComplete: () => { this.wrapper.classList.remove('no-action'); }
        });

        inViewAnimation.staggerFrom(firstLevelQuestions, 0.4, { ease: Back.easeOut.config(2), y: 20, scale: 0.92, autoAlpha: 0 }, 0.3 / firstLevelQuestions.length);

      }
    }
  }

  // navigate from current group to target group
  nagivate(current, target) {
    let headerHeight = this.header.getBoundingClientRect().height;
    let currentQuestions = [...current.querySelectorAll(`[data-level="${current.dataset.level}"]`)];
    let targetQuestions = [...target.querySelectorAll(`[data-level="${target.dataset.level}"]`)];
    let navigationAnim = new TimelineMax({
      onStart: () => { this.wrapper.classList.add('no-action'); },
      onComplete: () => { this.wrapper.classList.remove('no-action'); }
    });

    navigationAnim
    .to(window, 0.6, { ease: Power2.easeInOut, scrollTo: { y: getPosition(this.wrapper).y - headerHeight } })
    .staggerTo(currentQuestions, 0.4, { ease: Back.easeOut.config(2), y: -20, scale: 0.92, alpha: 0 }, 0.3 / currentQuestions.length, '-=.6')
    .add(() => {
      current.classList.add('hidden-group');
      target.classList.remove('hidden-group');
    })
    .set(currentQuestions, { clearProps: 'all' })
    .staggerFrom(targetQuestions, 0.4, { ease: Back.easeOut.config(2), y: 20, scale: 0.92, alpha: 0 }, 0.3 / targetQuestions.length, '+=.1');
  }

}