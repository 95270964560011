
//
// element-dataset 2.2.6
//
// element-dataset is released under the terms of the BSD-3-Clause license.
// (c) 2015 - 2017 Mark Milstein <mark@epiloque.com> 
//
// For all details and documentation: https://github.com/epiloque/element-dataset
//
  
'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var _Object$defineProperty = _interopDefault(require('babel-runtime/core-js/object/define-property'));
var _Object$getOwnPropertyDescriptor = _interopDefault(require('babel-runtime/core-js/object/get-own-property-descriptor'));

/* eslint-env browser */

function elementDatasetPolyfill() {
  if (!document.documentElement.dataset && (!_Object$getOwnPropertyDescriptor(HTMLElement.prototype, 'dataset') || !_Object$getOwnPropertyDescriptor(HTMLElement.prototype, 'dataset').get)) {
    var descriptor = {};

    descriptor.enumerable = true;

    descriptor.get = function get() {
      var element = this;
      var map = {};
      var attributes = this.attributes;

      function toUpperCase(n0) {
        return n0.charAt(1).toUpperCase();
      }

      function getter() {
        return this.value;
      }

      function setter(name, value) {
        if (typeof value !== 'undefined') {
          this.setAttribute(name, value);
        } else {
          this.removeAttribute(name);
        }
      }

      for (var i = 0; i < attributes.length; i += 1) {
        var attribute = attributes[i];

        // This test really should allow any XML Name without
        // colons (and non-uppercase for XHTML)

        if (attribute && attribute.name && /^data-\w[\w-]*$/.test(attribute.name)) {
          var name = attribute.name;
          var value = attribute.value;

          // Change to CamelCase

          var propName = name.substr(5).replace(/-./g, toUpperCase);

          _Object$defineProperty(map, propName, {
            enumerable: descriptor.enumerable,
            get: getter.bind({ value: value || '' }),
            set: setter.bind(element, name)
          });
        }
      }
      return map;
    };

    Object.defineProperty(HTMLElement.prototype, 'dataset', descriptor);
  }
}

exports['default'] = elementDatasetPolyfill;
