import {viewport, getPosition} from '../../helpers/helpers';
import {TweenLite} from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import 'isomorphic-fetch';

export default class CommunityMap {

	constructor(el) {
		this.mapEl = el;
		this.mapJSON = [];
		this.searchJSON = [];
		this.searched = false;
		this.topCategories = [];
		this.cities = [];
		this.vancouverLatLng = { lat: 49.264148, lng: -123.099192 };
		this.firstLoad = true;

		this.url = 'https://ramapi.kidshelpphone.ca/api/v2';
		this.token = '793FF1AA-8D1A-4E23-9B11-F26303F7C90D';

		this.clusterStyles = [{
			textColor: 'white',
			textSize: 10,
			url: iconBase + 'cluster-small.svg',
			height: 25,
			width: 25
		}, {
			textColor: 'white',
			textSize: 11,
			url: iconBase + 'cluster-medium.svg',
			height: 30,
			width: 30
		}, {
			textColor: 'white',
			textSize: 12,
			url: iconBase + 'cluster-large.svg',
			height: 40,
			width: 40
		}];

		this.mapStyles = [{
			'featureType': 'poi',
			'elementType': 'labels',
			'stylers': [{'visibility': 'off'}]
		}, {
			'featureType': 'transit',
			'elementType': 'all',
			'stylers': [{'visibility': 'off'}]
		}];

		this.filteredResults = [];

		this.selectedCity = '';
		this.selectedTopCategory = 0;

		this.body = document.querySelector('body');
		this.header = document.getElementById('site-header');
		this.footer = document.getElementById('site-footer');
		this.mapContainer = document.querySelector('.map-container');
		this.overlay = document.querySelector('.my-community__loading');

		this.filtersContainer = document.querySelector('.cmf');
		this.filtersHeader = this.filtersContainer.querySelector('.cmf__header');
		this.totalResults = this.filtersContainer.querySelector('.cmf__total-results');
		this.lists = this.filtersContainer.querySelector('.cmf__lists');
		this.backToTop = this.filtersContainer.querySelector('.cmf__to-top');
		this.cityFilter = document.getElementById('city-input');
		this.filteredBy = document.querySelector('.filtered-by');

		this.closestBtn = document.getElementById('closest-to-me');

		this.tooltipContainer = document.getElementById('tooltip-container');
		this.tooltipClose = document.getElementById('info-window-close');
		this.infoWindowContainer = this.tooltipContainer.querySelector('.info-window-container');

		this.keywordSearch = document.getElementById('keyword-search');
		this.keywordSearchField = document.getElementById('keyword-search-field');

		this.handheld = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

		this.fetchData();
	}

	events() {
		// Show all results on load
		this.filteredResults = this.mapJSON;
		this.updateResults();

		this.initAutoComplete();

		this.keywordSearch.addEventListener('submit', e => {
			e.preventDefault();
			this.keywordSearchField.blur();
			this.resetClosest();

			if ( this.keywordSearchField.value.trim().length > 0 ) {
				this.keywordSearchHandle();
			} else if ( this.searched ) {
				// Clear keyword filter if search input is empty
				this.resetKeyword();
			}
		});

		if ( this.closestBtn ) {
			this.closestBtn.addEventListener('click', e => {
				e.preventDefault();
				this.findClosest();
			});
		}

		if ( this.backToTop ) {
			this.backToTop.addEventListener('click', () => {
				this.scrollToTop(this.filtersContainer);
				this.scrollToTop(window);
			});
		}

		window.addEventListener('resize', () => {
			this.updatePositions();
		});
		
		// Pre-populates map from Topic parameter:
		if (window.location.search) {
			const urlParams = new URLSearchParams(window.location.search);
			const topic = urlParams.get('topic');
			console.log('Prepopulate ' + topic);
			this.selectedTopCategory = topic;
			console.log('Initializing ' + this.selectedTopCategory);
			document.querySelector('[data-category-id="' + topic + '"]').click();
			//this.updateResults(true);
		}
		
	}

	scrollToTop(container, duration = 0.8) {
		TweenLite.to(container, duration, { ease: Power2.easeInOut, scrollTo: { y: 0, autoKill: false } });
	}

	headerHeight() {
		return this.header.getBoundingClientRect().height;
	}

	findClosest() {
		// Double check if geo location is set
		if ( this.currentGeoLocation ) {
			let temp = [...this.mapJSON, ...foundryLocations];

			if ( this.selectedTopCategory != 0 ) {
				temp = temp.filter(loc => loc.TOPCategoryID == this.selectedTopCategory);
			}

			// Sort locations by distance from current location in ascending order
			// so that temp[0] will be the closest
			temp.sort((a, b) => {
				let distanceA = this.distanceToCurrent(a);
				let distanceB = this.distanceToCurrent(b);
				return distanceA > distanceB ? 1 : -1;
			});


			// Find all locations with the same distance as temp[0]
			const closestDistance = this.distanceToCurrent(temp[0]);
			temp = temp.filter(loc => {
				return this.distanceToCurrent(loc) === closestDistance;
			});

			this.filteredResults = temp;
			this.updateResults(true);

			this.addFilterTag('closest', 'Closest to Me');
		}
	}

	// Get distance to current location
	distanceToCurrent(location) {
		if ( this.currentGeoLocation ) {
			return google.maps.geometry.spherical.computeDistanceBetween(this.currentGeoLocation, new google.maps.LatLng(location.Latitude, location.Longitude));
		}
	}

	updatePositions() {
		if ( viewport().width <= 1024 ) {
			// TweenLite.to(this.mapContainer, 0.5, { top: this.filtersHeader.getBoundingClientRect().height + this.headerHeight() });
			// TweenLite.to(this.totalResults, 0.5, { marginTop: this.mapContainer.getBoundingClientRect().height + this.tooltipContainer.getBoundingClientRect().height });
			this.mapContainer.style.top = `${this.filtersHeader.getBoundingClientRect().height + this.headerHeight()}px`;
			this.totalResults.style.marginTop = `${this.mapContainer.getBoundingClientRect().height + this.tooltipContainer.getBoundingClientRect().height}px`;
		} else {
			this.mapContainer.style.top = '0px';
			this.totalResults.style.marginTop = '0px';
		}
	}

	keywordSearchHandle() {
		this.scrollToTop(window, 0.01);
		this.showOverlay(true);

		const input = this.keywordSearchField.value;
		const searchUrl = `${this.url}/Search/json/${this.token}/en/${input}`;
		let gotJson = false;

		this.addFilterTag('keyword', `Keyword: ${input}`);

		fetch(searchUrl)
		.then(response => response.json())
		.catch(e => {
			console.log(e);
			this.searched = false;
			this.showOverlay(false);
		})
		.then(json => {
			this.searched = true;
			if ( json ) {
				let tempIDs = [];
				this.searchJSON = json.filter((center, i, arr) => {
					if ( tempIDs.includes(center.ETLLoadID) ) {
						return false;
					} else {
						tempIDs.push(center.ETLLoadID);
						return true;
					}
				});

				gotJson = true;
				// console.log('got json')
			} else {
				// console.log('failed to get json')
			}
		})
		.then(() => {
			if ( gotJson ) {
				this.filterResults(this.searchJSON);
			} else {
				this.filteredResults = [];
			}
			this.updateResults();
			this.showOverlay(false);
		});
	}

	filterResults(json) {
		if (this.selectedCity && this.selectedTopCategory != 0) {
			this.filteredResults = json.filter(loc => loc.TOPCategoryID == this.selectedTopCategory && loc.City === this.selectedCity);
		} else if (this.selectedCity) {
			this.filteredResults = json.filter(loc => loc.City === this.selectedCity);
		} else if (this.selectedTopCategory != 0) {
			this.filteredResults = json.filter(loc => loc.TOPCategoryID == this.selectedTopCategory);
		} else {
			this.filteredResults = json;
		}
	}

	fetchData() {

		const allLocationsUrl = `${this.url}/resource/json/${this.token}/en`;
		const categoriesUrl = `${this.url}/TopCategory/json/${this.token}/en`;

		fetch(allLocationsUrl) // fetch all locations
		.then(response => response.json())
		.catch(e => console.log('Failed to fetch location data'))
		.then(json => {
			let tempIDs = [];
			this.mapJSON = json.filter((center, i, arr) => {
				if ( tempIDs.includes(center.ETLLoadID) ) {
					return false;
				} else {
					tempIDs.push(center.ETLLoadID);
					return true;
				}
			});
		})
		.then(() => fetch(categoriesUrl)) // fetch top categories
		.then(response => response.json())
		.catch(e => console.log('Failed to fetch category data'))
		.then(json => {
			this.topCategories = json.sort((a,b) => a.TopCategory > b.TopCategory);
		})
		.then(() => {
			this.initMap();
			this.initFilters();
			this.events();
		});

	}

	// Toggle loading overlay
	showOverlay(show) {
		if ( show ) {
			this.overlay.classList.remove('hide');
			this.footer.classList.remove('active');
		} else {
			this.overlay.classList.add('hide');
			this.footer.classList.add('active');
		}
	}

	initFilters() {

		this.filtersContainer.classList.add('active');
		this.updatePositions();

		// Get a list of cities
		this.cities = this.uniqueArray('City');

		const deskEl = document.querySelector('.categories--desk');
		const mobileEl = document.querySelector('.categories--mobile');

		if ( this.handheld && mobileEl ) {

			/* MOBILE CATEGORY FILTER */

			deskEl.parentNode.removeChild(deskEl);
			mobileEl.classList.add('show-select');

			const select = mobileEl.querySelector('.categories__select');

			/* Initialize select options */
			this.topCategories.forEach(topCategory => {
				const option = document.createElement('option');
				option.setAttribute('value', topCategory.TopCategoryID);
				option.innerHTML = topCategory.TopCategory;

				select.appendChild(option);
			});

			select.addEventListener('change', e => {
				let id = select.value;

				if ( id == 999 ) { // All Categories
					this.resetCategory();
				} else {
					this.resetClosest();
					this.selectedTopCategory = id;
					this.addFilterTag('category', this.topCategories.find(loc => loc.TopCategoryID == id).TopCategory);
					this.filteredResults = this.selectedCity ? this.filterByCityAndTopCategory() : this.filterByTopCategory();
					this.updateResults();
				}
			});

		} else if ( deskEl ) {

			/* DESKTOP CATEGORY FILTER */

			mobileEl.parentNode.removeChild(mobileEl);
			deskEl.classList.add('show-select');

			const select = deskEl.querySelector('.categories__select');
			const copy = select.querySelector('span');
			const dropdown = deskEl.querySelector('.categories__dropdown');
			const selectAll = dropdown.querySelector('.categories__dropdown-item[data-all]');

			// Initialize category filter dropdown
			this.topCategories.forEach(topCategory => {
				const dropdownItem = document.createElement('div');
				dropdownItem.classList.add('categories__dropdown-item');
				dropdownItem.setAttribute('data-category-id', topCategory.TopCategoryID);
				dropdownItem.setAttribute('title', topCategory.TopCategory);
				dropdownItem.innerHTML = topCategory.TopCategory;
				dropdown.appendChild(dropdownItem);

				dropdownItem.addEventListener('click', () => {
					this.resetClosest();
					this.selectedTopCategory = topCategory.TopCategoryID;
					const categoryTitle = dropdownItem.getAttribute('title');
					copy.innerHTML = categoryTitle;
					console.log('Click on category ' + categoryTitle);
					this.addFilterTag('category', categoryTitle);
					this.filteredResults = this.selectedCity ? this.filterByCityAndTopCategory() : this.filterByTopCategory();
					this.updateResults();
				});
			});

			// Remove category filter if 'all categories' is selected
			selectAll.addEventListener('click', this.resetCategory.bind(this));

			// Toggle desktop category dropdown
			deskEl.addEventListener('click', () => {
				deskEl.classList.contains('is-open') ? deskEl.classList.remove('is-open') : deskEl.classList.add('is-open');
			});

			// Close desktop category dropdown when clicking anywhere outside of category container
			this.body.addEventListener('click', e => {
				if ( select != e.target ) {
					deskEl.classList.remove('is-open');
				}
			});
		}
	}

	resetCategory() {
		this.selectedTopCategory = 0;
		let temp = this.searched ? this.searchJSON : this.mapJSON;
		this.filteredResults = this.selectedCity ? this.filterByCity() : temp;

		const deskEl = document.querySelector('.categories--desk');
		const mobileEl = document.querySelector('.categories--mobile');

		if ( this.handheld && mobileEl ) {
			const select = mobileEl.querySelector('.categories__select');
			select.selectedIndex = '0';
		} else if ( deskEl ) {
			const copy = deskEl.querySelector('.categories__select span');
			const selectAll = deskEl.querySelector('.categories__dropdown-item[data-all]');
			copy.innerHTML = selectAll.getAttribute('title');
		}
		this.removeFilterTag('category');
		this.updatePositions();
		this.updateResults();
	}

	resetCity() {
		this.selectedCity = '';
		let temp = this.searched ? this.searchJSON : this.mapJSON;
		this.filteredResults = this.selectedTopCategory != 0 ? this.filterByTopCategory() : temp;

		this.cityFilter.value = '';
		this.removeFilterTag('city');
		this.updatePositions();
		this.updateResults();
	}

	resetKeyword() {
		this.searched = false;
		this.searchJSON = [];
		this.filterResults(this.mapJSON);

		this.keywordSearchField.value = '';
		this.removeFilterTag('keyword');
		this.updatePositions();
		this.updateResults();
	}

	resetClosest(tagClicked = false) {

		this.removeFilterTag('closest');

		if ( tagClicked ) {
			this.searched ? this.filterResults(this.searchJSON) : this.filterResults(this.mapJSON);
			this.updatePositions();
			this.updateResults();
		}
	}

	// Initialize map
	initMap() {

		this.map = new google.maps.Map(this.mapEl, {
			zoom: 8,
			center: this.vancouverLatLng,
			scrollwheel: false,
			minZoom: 4,
			disableDefaultUI: true,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			zoomControl: true,
			zoomControlOptions: {
				position: google.maps.ControlPosition.LEFT_TOP
			},
			styles: this.mapStyles
		});

		// Close info tooltip if...
		google.maps.event.addListener(this.map, 'click', this.closeTooltip.bind(this));
		google.maps.event.addListener(this.map, 'dragstart', this.closeTooltip.bind(this));
		google.maps.event.addListener(this.map, 'zoom_changed', this.closeTooltip.bind(this));
		google.maps.event.addListener(this.map, 'resize', this.closeTooltip.bind(this));
		window.addEventListener('resize', this.closeTooltip.bind(this));
		this.tooltipClose.addEventListener('click', this.closeTooltip.bind(this, false));
	}

	/*
	Include foundry when filtered results do not include foundry locations
	Do not include foundry when filtered results include foundry locations
	*/
	dropMarkers(includeFoundry) {

		// Scroll to map on mobile
		if ( viewport().width <= 1024 && !this.firstLoad ) {
			window.requestAnimationFrame(() => {
				const mapPosition = getPosition(this.mapContainer).y - this.headerHeight();
				TweenLite.to(window, 0.8, { ease: Power2.easeInOut, scrollTo: { y: mapPosition, autoKill: false } });
			});
		}

		this.firstLoad = false;

		// Reset marker cluster
		if ( this.markerCluster ) {
			this.markerCluster.clearMarkers();
		}

		const bounds = new google.maps.LatLngBounds();

		// Remove private locations and locations with inaccurate laglng or no physical address
		let results = this.filteredResults.filter(center => center.Latitude != 0 && center.PhysicalAddressIsPrivate === 'No' && center.PhysicalAddress !== '' && center.Name.toLowerCase().indexOf('foundry') === -1);

		if (includeFoundry) {
			// Filter foundry locations if a city has been selected
			let filteredFoundryLocations = foundryLocations;
			if (this.selectedCity) {
				filteredFoundryLocations = foundryLocations.filter(center => center.City == this.selectedCity);
			}
			results = [...results, ...filteredFoundryLocations];
		}

		// Group filtered results laglng
		const resultsByLaglng = results.reduce((obj, center) => {
			let name = `${center.Latitude.toFixed(6)}, ${center.Longitude.toFixed(6)}`;

			if ( !obj[name] ) {
				obj[name] = [];
			}

			obj[name] = [...obj[name], center];
			return obj;
		}, {});

		this.markers = [];

		for (const laglng in resultsByLaglng) {
			if (resultsByLaglng.hasOwnProperty(laglng)) {

				// Group of locations with the same laglng
				const group = resultsByLaglng[laglng];
				let hasFoundry = false;

				for (let i = 0; i < group.length; i++) {
					let center = group[i];
					let markerImage = 'pin-blue-2.png';
					let markerAnchor = new google.maps.Point(10, 28);
					let markerSize = new google.maps.Size(20.5, 28);

					// Different marker styles for foundry markers
					if ( center.isFoundry ) {
						hasFoundry = true;
						markerImage = 'foundry-marker-shadow.png';
						markerAnchor = new google.maps.Point(2, 54.7);
						markerSize = new google.maps.Size(126, 54.7);
					}

					const position = new google.maps.LatLng(center.Latitude, center.Longitude);

					const marker = new google.maps.Marker({
						position: position,
						icon: {
							anchor: markerAnchor,
							scaledSize: markerSize,
							url: iconBase + markerImage
						}
					});

					/*
					Add info window to a marker and make it visible only if it's either a foundry
					marker or it's the last marker in a group that does not contain foundry locations
					*/
					if ( !hasFoundry && i !== group.length - 1 ) {
						marker.setVisible(false);
					} else {
						this.addInfoWindowGroup(marker, group, this, hasFoundry);
					}

					bounds.extend(marker.getPosition());

					this.markers.push(marker);
				}
			}
		}

		if ( this.markers.length > 0 ) {

			// Don't zoom in too far on only one marker
			const ne = bounds.getNorthEast();
			if (ne.equals(bounds.getSouthWest())) {
				const etdP1 = new google.maps.LatLng(ne.lat() + 0.01, ne.lng() + 0.01);
				const etdP2 = new google.maps.LatLng(ne.lat() - 0.01, ne.lng() - 0.01);
				bounds.extend(etdP1);
				bounds.extend(etdP2);
			}

			this.map.fitBounds(bounds);
		}

		this.markerCluster = new MarkerClusterer(this.map, this.markers, {gridSize: 40, styles: this.clusterStyles, maxZoom: 19});
	}

	addInfoWindowGroup(marker, group, _this, hasFoundry) {
		google.maps.event.addListener(marker, 'click', function(marker) {
			return function() {
				const map = _this.map;
				const mapCenter = _this.getRoundedPosition(map.getCenter(), 6);
				const markerPosition = _this.getRoundedPosition(marker.getPosition(), 6);

				// If it's a foundry marker, hide it when its tooltip is open

				if ( viewport().width > 1024 && hasFoundry ) {
					marker.setVisible(false);
				}

				if ( mapCenter === markerPosition ) {
					// if marker is already at the center
					_this.openMegaTooltip(group, hasFoundry);
				} else {
					// pan then open tooltip otherwise
					_this.closeTooltip();
					map.panTo(marker.getPosition());
					google.maps.event.addListenerOnce(map, 'idle', function() {
						_this.openMegaTooltip(group, hasFoundry);
					});
				}

				_this.activeMarker = marker;
			}
		}(marker));
	}

	openMegaTooltip(group, hasFoundry) {

		this.infoWindowContainer.innerHTML = '';

		if ( hasFoundry ) {
			this.tooltipContainer.classList.add('is-foundry');
		}

		let finalMarkup = '';

		group.forEach(center => {
			finalMarkup += this.createIndividualInfoWindow(center);
		});

		this.infoWindowContainer.innerHTML = finalMarkup;
		this.tooltipContainer.classList.add('active');

		window.requestAnimationFrame(()=>{
			this.updatePositions();

			// Scroll to tooltip on mobile
			if ( viewport().width <= 1024 ) {
				const tooltipPosition = getPosition(this.tooltipContainer).y - this.headerHeight();
				TweenLite.to(window, 0.8, { ease: Power2.easeInOut, scrollTo: { y: tooltipPosition, autoKill: false } });
			}
		});

	}

	createIndividualInfoWindow(center) {

		const address = center.PhysicalAddress;
		const city = center.City;
		const province = center.Province;
		const phone = center.Phone;
		const website = center.WebsiteAddress;
		let postalCode = center.PhysicalPostalCode;

		if ( postalCode ) postalCode = postalCode.toUpperCase();

		let addressStr = '';
		let phoneClass = phone ? ' active' : '';
		let websiteClass = website ? ' active' : '';
		let moreLink = center.isFoundry ? center.FoundryLink : `${programUrl}?${this.getShortName(center.Name)}&id=${center.ETLLoadID}`;

		if ( address ) {
			addressStr = address;
			if ( city ) addressStr += `, ${city}`;
			if ( province ) addressStr += `, ${province}`;
			if ( postalCode ) addressStr += `, ${postalCode}`;
		} else {
			if ( city ) addressStr += `${city}`;
			if ( province ) addressStr += `, ${province}`;
			if ( (city || province) && postalCode ) addressStr += `, ${postalCode}`;
		}

		return `<div class="info-window">
			<div class="info-window__header">
				<div class="info-window__header-container">
					<div id="info-window-name">${center.Name}</div>
					<div id="info-window-address">${addressStr}</div>
				</div>
			</div>
			<div class="info-window__footer">
				<a href="tel:${phone}" id="info-window-phone" class="info-window-col${phoneClass}">
					<i class="material-icons">call</i>
					<p href="#">${phone}</p>
				</a>
				<a href="http://${website}" id="info-window-website" class="info-window-col${websiteClass}" target="_blank">
					<i class="material-icons">desktop_windows</i>
					<p href="#">Website</p>
				</a>
				<a href=${moreLink} id="info-window-more" class="info-window-col active">
					<i class="material-icons">more_horiz</i>
					<p href="#">More Details</p>
				</a>
			</div>
		</div>`;
	}

	getRoundedPosition(a, b) {
		var c = {
			lat: parseFloat(a.toJSON().lat.toFixed(b)),
			lng: parseFloat(a.toJSON().lng.toFixed(b))
		};
		return JSON.stringify(c);
	}

	// if desktopOnly is true, don't close the tooltip on mobile
	closeTooltip(desktopOnly = true) {
		if ( !desktopOnly || viewport().width >= 1024 ) {
			this.tooltipContainer.classList.remove('active');
			this.tooltipContainer.classList.remove('is-foundry');

			if ( this.activeMarker ) {
				this.activeMarker.setVisible(true);
				this.activeMarker = null;
			}

			window.requestAnimationFrame(()=>{
				this.updatePositions();
			});
		}
	}

	reset() {
		this.filteredResults = this.mapJSON;
	}

	// Filter by city
	filterByCity() {
		let temp = this.searched ? this.searchJSON : this.mapJSON;
		return temp.filter(loc => loc.City === this.selectedCity);
	}

	// Filter by top category
	filterByTopCategory() {
		let temp = this.searched ? this.searchJSON : this.mapJSON;
		return temp.filter(loc => loc.TOPCategoryID == this.selectedTopCategory);
	}

	// Filter by city and top category
	filterByCityAndTopCategory() {
		let temp = this.searched ? this.searchJSON : this.mapJSON;
		return temp.filter(loc => loc.TOPCategoryID == this.selectedTopCategory && loc.City === this.selectedCity);
	}

	autoCompleteSource(term, suggest) {
		term = term.toLowerCase();
		let suggestions = [];

		if ( this.app.cities ) {
			for (let i = 0; i < this.app.cities.length; i++) {
				let city = this.app.cities[i];

				if (city.toLowerCase().indexOf(term) > -1) {
					suggestions.push(city);
				}
			}
		}

		suggest(suggestions);
	}

	autoCompleteRenderItem(item, search) {
		search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
		let re = new RegExp("(" + search.split(' ').join('|') + ")", "gi");
		let itemHTML = `<div class="autocomplete-suggestion" data-val="${item}">${item.replace(re, "<b>$1</b>")}</div>`;
		return itemHTML;
	}

	autoCompleteOnSelect(e, term, item) {
		this.app.selectedCity = term;
		this.app.filteredResults = this.app.selectedTopCategory == 0 ? this.app.filterByCity() : this.app.filterByCityAndTopCategory();
		this.app.updateResults();
		this.app.cityFilter.blur();
		this.app.addFilterTag('city', term);
		this.app.resetClosest();
	}

	initAutoComplete() {

		this.searchAutoComplete = new autoComplete({
			selector: '#city-input',
			minChars: 2,
			source: this.autoCompleteSource,
			renderItem: this.autoCompleteRenderItem,
			onSelect: this.autoCompleteOnSelect,
			app: this
		});
	}

	addFilterTag(attr, term) {

		if ( this.filteredBy.hasAttribute(attr) && this.filteredBy.getAttribute(attr) !== term ) {
			// If a tag with the same attribute already exists, overwrite it
			const tag = this.filteredBy.querySelector(`.filter-tag--${attr}`);
			this.filteredBy.setAttribute(attr, term);
			tag.innerHTML = `${term}<i class="material-icons">clear</i>`;
		} else if ( !this.filteredBy.hasAttribute(attr) ) {
			// Otherwise, create a new one
			const tag = document.createElement('div');
			tag.className = `filter-tag filter-tag--${attr}`;
			this.filteredBy.appendChild(tag);
			TweenLite.from(tag, 0.4, { ease: Elastic.easeOut.config(1, 0.75), scale: 0, alpha: 0 });
			this.filteredBy.setAttribute(attr, term);
			tag.innerHTML = `${term}<i class="material-icons">clear</i>`;

			tag.addEventListener('click', this.resetAttr.bind(this, attr));
		}

		this.updatePositions();
	}

	removeFilterTag(attr) {
		const tag = this.filteredBy.querySelector(`.filter-tag--${attr}`);
		if ( tag && this.filteredBy.hasAttribute(attr) ) {
			this.filteredBy.removeAttribute(attr);
			tag.parentNode.removeChild(tag);
		}
	}

	resetAttr(attr) {
		if ( this.filteredBy.hasAttribute(attr) ) {
			this.resetClosest();
			switch (attr) {
				case 'city'     : this.resetCity(); break;
				case 'category' : this.resetCategory(); break;
				case 'keyword'  : this.resetKeyword(); break;
				case 'closest'  : this.resetClosest(true); break;
			}
		}
	}

	updateTotal() {
		this.totalResults.classList.add('active');
		const span = this.totalResults.querySelector('span');
		span.innerHTML = this.filteredResults.length;
	}

	updateResults(findClosest = false) {
		if ( !this.currentGeoLocation ) {
			navigator.geolocation.getCurrentPosition( position => {

				const lat = position.coords.latitude;
				const lng = position.coords.longitude;

				this.currentGeoLocation = new google.maps.LatLng(lat, lng);
				console.log('updateResults: Loading users location');
				if (this.firstLoad) {
					fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDAPdfmjgFFQXYE_uyPRplndhv7q_BsZLQ`)
					.then(response => response.json())
					.catch(e => console.log('Failed to get location data'))
					.then(json => {
						let temp = json.results.find(r => r.types.includes('locality') && r.types.includes('political'));
						temp = temp.address_components.find(c => c.types.includes('locality') && c.types.includes('political'));
						this.userCity = temp.long_name;

						if (this.cities.includes(this.userCity)) {
							this.selectedCity = this.userCity;
							this.addFilterTag('city', this.userCity);
							console.log('updateResults  Filter by City');
							if (this.selectedTopCategory) {
								this.filteredResults = this.filterByCityAndTopCategory();
							} else {
							this.filteredResults = this.filterByCity();
							}
						}

						this.updateList();
						this.closestBtn.classList.add('active');
					});

					// const curLocationMarker = new google.maps.Marker({
					// 	position: {lat: lat, lng: lng},
					// 	map: this.map
					// });


				} else {
					this.updateList();
					this.closestBtn.classList.add('active');
				}

			}, err => {
				console.log('Unable to get geolocation');
				this.updateList();
			});
			console.log('updateResults: no currentGeoLocation');
		} else {
			console.log('updateResults: ' + this.currentGeoLocation);
			this.updateList(findClosest);
		}
	}

	updateList(findClosest = false) {
		console.log('UpdateList: ' + findClosest);
		this.showOverlay(false);
		this.updateTotal();
		this.addTopCatLists();
		this.dropMarkers(!findClosest); // include foundry markers when request comes from find closest
	}

	addTopCatLists() {
		this.lists.innerHTML = '';
		if ( this.filteredResults.length > 0 ) {
			const data = this.groupTopCats();
			for (const topCategory in data) {
				if (data.hasOwnProperty(topCategory)) {
					this.addTopCatList(topCategory, data[topCategory]);
				}
			}
		}
	}

	addTopCatList(title, data) {
		// Top category container
		const topCatContainer = document.createElement('div');
		topCatContainer.classList.add('top-category-container');

		this.addTopCatHeader(topCatContainer, title);
		this.addSubCatsContainer(topCatContainer, data);

		this.lists.appendChild(topCatContainer);
	}

	addTopCatHeader(el, title) {
		// Top category header
		const topCatHeader = document.createElement('div');
		topCatHeader.classList.add('top-category-header');
		topCatHeader.innerHTML = `<h3>${title}</h3>`;
		el.appendChild(topCatHeader);
	}

	addSubCatsContainer(el, data) {
		// Container for all sub category container
		const subCatsContainer = document.createElement('div');
		subCatsContainer.classList.add('sub-categories-container');

		const categoryData = this.groupSubCats(data);

		for (const subCategory in categoryData) {
			if (categoryData.hasOwnProperty(subCategory)) {
				this.addSubCatList(subCatsContainer, subCategory, categoryData[subCategory]);
			}
		}

		el.appendChild(subCatsContainer);
	}

	// Group all results based on top category
	groupTopCats() {
		let obj = this.filteredResults.reduce((o, item) => {
			let topCategory = item.TopCategory;
			if ( !o[topCategory] ) {
				o[topCategory] = [];
			}

			o[topCategory] = [...o[topCategory], item];
			return o;
		}, {});

		return this.sortByKey(obj);
	}

	// Group results of the same top category based on sub category
	groupSubCats(topCatData) {
		let obj = topCatData.reduce((o, item) => {
			let subCategory = item.SubCategory;
			if ( !o[subCategory] ) {
				o[subCategory] = [];
			}

			o[subCategory] = [...o[subCategory], item];
			return o;
		}, {});

		return this.sortByKey(obj);
	}

	sortByKey(obj) {
		return Object.keys(obj).sort().reduce((r, k) => (r[k] = obj[k], r), {});
	}

	// Add subcat list to subcats container (el)
	addSubCatList(el, subcat, centers) {

		const total = centers.length;

		// Sub category container
		const subCatContainer = document.createElement('div');
		subCatContainer.classList.add('sub-category-container');

		const subCatHeader = this.addSubCatHeader(subCatContainer, subcat, total);
		const locationsContainer = this.addLocationsContainer(subCatContainer, centers, total);

		this.subCatHeaderHandle(subCatHeader, locationsContainer, subCatContainer);

		el.appendChild(subCatContainer);
	}

	// Add sub category header to subcat conatiner (el)
	addSubCatHeader(el, title, total) {
		const subCatHeader = document.createElement('div');
		subCatHeader.classList.add('sub-category-header');
		subCatHeader.innerHTML = `<h4>${title}<span>(${total})</span></h4>`;

		// Sub category accordion arrow
		const subCatToggle = document.createElement('div');
		subCatToggle.classList.add('sub-category-toggle');
		subCatToggle.innerHTML = '<span></span><span></span>';
		subCatHeader.appendChild(subCatToggle);

		el.appendChild(subCatHeader);
		return subCatHeader;
	}

	// Add locations container to subcat container (el)
	addLocationsContainer(el, centers, total) {
		const locationsContainer = document.createElement('div');
		locationsContainer.classList.add('locations-container');

		el.setAttribute('data-count', total);

		centers.forEach(center => {
			this.addListItem(center, locationsContainer);
		});

		if ( total > 10 ) {
			this.addShowMoreBtn(locationsContainer);
		}

		el.appendChild(locationsContainer);

		return locationsContainer;
	}

	// Add show more button and its event listener to locations container (el)
	addShowMoreBtn(el) {
		const showMoreBtn = document.createElement('div');
		showMoreBtn.classList.add('show-more-btn');
		showMoreBtn.innerHTML = 'Show More';
		el.appendChild(showMoreBtn);

		// Show the next ten locations
		showMoreBtn.addEventListener('click', () => {
			const inactiveLocations = [...el.querySelectorAll('.location-container:not(.active)')];
			for ( let i = 0; i < Math.min(10, inactiveLocations.length); i++ ) {
				inactiveLocations[i].classList.add('active');
			}

			// Hide show more button if all locations in this sub category are shown
			if ( inactiveLocations.length <= 10 ) {
				showMoreBtn.classList.add('inactive');
			}

		});
	}

	subCatHeaderHandle(subCatHeader, locationsContainer, subCatContainer) {
		subCatHeader.addEventListener('click', () => {
			const allChildLocations = [...locationsContainer.querySelectorAll('.location-container')];

			if ( subCatContainer.classList.contains('active') ) {
				this.hideSubCat(locationsContainer, subCatContainer, allChildLocations);
			} else {
				this.expandSubCat(locationsContainer, subCatContainer, allChildLocations);
			}
		});
	}

	expandSubCat(locationsContainer, subCatContainer, allChildLocations) {
		subCatContainer.classList.add('active');

		// Display the first ten locations
		for (let i = 0; i < Math.min(allChildLocations.length, 10); i++) {
			allChildLocations[i].classList.add('active');
		}

		TweenLite.set(locationsContainer, { height: 'auto' });
		TweenLite.from(locationsContainer, 0.5, { ease:Power2.easeInOut, height: 0 });
	}

	hideSubCat(locationsContainer, subCatContainer, allChildLocations) {
		// Hide sub category container
		subCatContainer.classList.remove('active');

		TweenLite.to(locationsContainer, 0.5, { ease:Power2.easeInOut, height: 0, onComplete: () => {
			// Hide all active locations
			allChildLocations.forEach(loc => {
				loc.classList.remove('active');
			});

			// Display show more button
			const selectShowMoreBtn = locationsContainer.querySelector('.show-more-btn');

			if ( selectShowMoreBtn ) {
				selectShowMoreBtn.classList.remove('inactive');
			}
		}});
	}

	// Add list item to locations container (el)
	addListItem(center, el) {
		const locationContainer = document.createElement('div');
		locationContainer.classList.add('location-container');
		locationContainer.setAttribute('data-id', center.ETLLoadID);

		this.addListItemName(center, locationContainer);
		this.addListItemTerms(center, locationContainer);
		this.addListItemType(center, locationContainer);

		el.appendChild(locationContainer);
	}

	// Add location name as a link to list item (el)
	addListItemName(center, el) {
		const locationName = document.createElement('a');
		locationName.classList.add('location-name');
		locationName.setAttribute('href', `${programUrl}?${this.getShortName(center.Name)}&id=${center.ETLLoadID}`);
		locationName.innerHTML = center.Name;
		el.appendChild(locationName);
	}

	getShortName(longName) {
		return longName.toLowerCase().replace(/'/g, '').replace(/\W+/g, '-');
	}

	// Add sterms to list item (el)
	addListItemTerms(center, el) {
		const locationTermsUl = document.createElement('ul');
		locationTermsUl.classList.add('location-terms');
		el.appendChild(locationTermsUl);

		const sterms = center.STerm.split('; ');
		sterms.pop();

		for (let i = 0; i < Math.min(5, sterms.length); i++) {
			const locationTerm = document.createElement('li');
			locationTerm.innerHTML = `<span>${sterms[i]}</span>`;
			locationTermsUl.appendChild(locationTerm);
		}
	}

	// Add list type to list item (el)
	addListItemType(center, el) {
		if ( center.PhysicalAddressIsPrivate === 'No' ) {
			this.addListItemLocation(center, el)
		} else if ( center.Phone ) {
			this.addListItemPhone(center, el);
		}
	}

	// List type location
	addListItemLocation(center, el) {
		if ( center.City ) {
			const locationExtra = document.createElement('span');
			locationExtra.classList.add('location-extra');
			locationExtra.innerHTML = center.City;

			if ( center.Province ) {
				locationExtra.innerHTML += `, ${center.Province}`;
			}

			el.appendChild(locationExtra);
			const locationIcon = document.createElement('i');
			locationIcon.classList.add('material-icons');
			locationIcon.innerHTML = 'room';
			el.appendChild(locationIcon);
		}

		el.setAttribute('data-type', 'location');

		// Add distance to list item
		if ( this.currentGeoLocation && center.Latitude != 0 ) {
			const locationDistance = document.createElement('span');
			locationDistance.classList.add('location-distance');
			let distance = google.maps.geometry.spherical.computeDistanceBetween(this.currentGeoLocation, new google.maps.LatLng(center.Latitude, center.Longitude));
			distance = Math.round(distance/10) / 100;
			locationDistance.innerHTML = `${distance} KM`;
			el.appendChild(locationDistance);
		}
	}

	// List type phone
	addListItemPhone(center, el) {
		const locationExtra = document.createElement('a');
		locationExtra.classList.add('location-extra');
		locationExtra.setAttribute('href', `tel:${center.Phone}`);
		locationExtra.innerHTML = center.Phone;
		el.appendChild(locationExtra);
		el.setAttribute('data-type', 'phone');

		const locationIcon = document.createElement('i');
		locationIcon.classList.add('material-icons');
		locationIcon.innerHTML = 'call';
		el.appendChild(locationIcon);
	}

	// E.g. uniqueArray('TopCategory') will return an array of all top categories with no duplicates
	uniqueArray(attr) {
		return this.mapJSON.map(center => center[attr]).filter((elem, pos, arr) => arr.indexOf(elem) == pos);
	};

}