import getClosest from '../helpers/closest'
import throttle from 'lodash.throttle'
import {viewport} from '../helpers/helpers'
import {TweenLite} from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

export default class Header {

  constructor(){
    // Setting all the elements to be used
    this.htmlEl = document.querySelector('html')
    this.bodyEl = document.querySelector('body')
    this.siteNavOverlay = document.getElementById('site-nav-overlay')
    this.headerEl = document.querySelector('.site-header')
    this.siteNavEl = document.querySelector('.site-nav-drawer')
    this.internalNavTrigger = document.querySelector('[data-internal-nav]')
    this.headerNav = document.getElementById('navigation');
    this.siteHeaderInternalNav = document.querySelector('.site-header-internal-nav');

    // this.searchOpenTrigger = document.querySelector('.search-area-trigger')
    // this.searchCloseTrigger = document.querySelector('.search-form__close')
    this.searchFields = [...document.querySelectorAll('.search-field')];

    this.menuTrigger = document.querySelector('.menu-trigger')
    this.dropdownTriggers = Array.from(document.querySelectorAll('.menu-item-has-children .dropdown-arrow'))
    this.eServicesDropdownTriggers = Array.from(document.querySelectorAll('.e-services-nav .menu-item-has-children > .menu-item-container .dropdown-arrow'));

    this.scrollToTopBtn = document.getElementById('scroll-to-top');

    this.init();
    this.events()

    // Do an inital load as the page may already be scrolled on a refresh
    this.scrollHandle()

    this.siteNavClickWatcher = this.siteNavClick.bind(this)

    this.navOpen = false
    this.internalNavOpen = false
    this.moreNavActive = false;
  }

  init() {
    this.eServicesDropdownTriggers.forEach(el => {
      //this.dropdownHandle(el, 'init');
    });
    this.dropdownHandle(this.eServicesDropdownTriggers[0]);
  }

  /**
   * Watches for all the UI events
   *
   * @memberof Header
   */
  events() {
    // Binding the context here so all functions use the same bound reference
    // Allowing listener to the be removed
    this.menuTriggerHandle = this.menuTriggerHandle.bind(this)
    this.closeAll = this.closeAll.bind(this)
    // this.closeSearch = this.closeSearch.bind(this)
    // this.openSearch = this.openSearch.bind(this)
    this.toggleInternalNav = this.toggleInternalNav.bind(this)

    // Throttled scroll (1000/60 = 16) 60 fps
    this.scrollHandle = throttle(this.scrollHandle, 16).bind(this)
    window.addEventListener('scroll', this.scrollHandle)

    this.menuTrigger.addEventListener('click', this.menuTriggerHandle)
    this.siteNavOverlay.addEventListener('click', this.closeAll)

    // this.searchCloseTrigger.addEventListener('click', this.closeSearch)
    // this.searchOpenTrigger.addEventListener('click', this.openSearch)

    this.searchFields.forEach(el => {
      el.addEventListener('focus', this.searchOnFocus.bind(this, el));
      el.addEventListener('blur', this.searchOnBlur.bind(this, el));
    });

    this.dropdownTriggers.forEach((el, i) => {
      el.addEventListener('click', this.dropdownHandle.bind(this, el))
    })

    if(this.internalNavTrigger) {
      this.internalNavTrigger.addEventListener('click', this.toggleInternalNav, true)
    }

    this.scrollToTopBtn.addEventListener('click', this.scrollToTop)

    window.addEventListener('resize', this.resizeHandle.bind(this));
  }

  // Menu toggle handled here
  menuTriggerHandle(e){
    e.preventDefault()
    this.navOpen ? this.closeNav() : this.openNav()
  }

  // Open main side nav
  openNav(){
    this.navOpen = true
    this.bodyEl.classList.add('nav-open')
    this.htmlEl.classList.add('no-scroll');
    this.storeActiveElement()
    this.siteNavEl.addEventListener('click', this.siteNavClickWatcher)
  }

  // Close main side nav
  closeNav(){
    this.navOpen = false
    this.bodyEl.classList.remove('nav-open')
    this.htmlEl.classList.remove('no-scroll');
    this.restoreActiveElement()
    this.siteNavEl.removeEventListener('click', this.siteNavClickWatcher)
  }

  // Used to stop the event propogating and triggering unwanted click handlers
  siteNavClick(e){
    e.stopPropagation()
  }

  // Handles toggling the children elements in the nav
  dropdownHandle(el, e){
    const parentLi = getClosest(el, '.menu-item-has-children');
    const submenu = parentLi.querySelector('.sub-menu');
    parentLi.classList.contains('submenu-open') ? this.closeDropdown(parentLi, submenu) : this.openDropdown(parentLi, submenu)
  }

  // Open dropdown of the passed elements
  openDropdown(parentLi, submenu){

    parentLi.classList.add('submenu-open')
    const last = submenu.getBoundingClientRect()
    submenu.style.height = 0 + 'px'
    submenu.style.overflow = 'hidden'

    submenu.style.transition = 'height .3s'

    // Wait one rendered frame before applying the height
    window.requestAnimationFrame(()=>{
      submenu.style.height = `${last.height}px`
    })
  }

  // Close dropdown
  closeDropdown(parentLi, submenu){
    submenu.style.height = 0 + 'px'
    submenu.addEventListener('transitionend', function transEnd(){
      parentLi.classList.remove('submenu-open')
      submenu.style.height = 'auto'
      submenu.removeEventListener('transitionend', transEnd)
    })
  }

  // Triggered on each scroll event
  scrollHandle(e) {
    let scrolled = window.pageYOffset

    if(scrolled > 0){
      this.bodyEl.classList.add('scrolled')
    }else{
      this.bodyEl.classList.remove('scrolled')
    }

    if (scrolled > 300) {
      this.scrollToTopBtn.classList.add('active');
    } else {
      this.scrollToTopBtn.classList.remove('active');
    }
  }

  // Used for toggling the internal page nav
  toggleInternalNav(e) {
    if(window.matchMedia('(max-width: 1200px)').matches || !this.siteHeaderInternalNav.classList.contains('desktop-view')) {
      if(!this.internalNavOpen) e.preventDefault()
      this.internalNavOpen ? this.closeInternalNav() : this.openInternalNav()
    }
  }

  // open the internal page nav
  openInternalNav(){
    this.internalNavOpen = true
    this.storeActiveElement()
    this.bodyEl.classList.add('open-internal-nav')
  }

  // Close internal nav
  closeInternalNav(){
    this.internalNavOpen = false
    this.restoreActiveElement()
    this.bodyEl.classList.remove('open-internal-nav')
  }

  // Open the search bar
  // openSearch(){
  //   this.searchOpen = true
  //   this.storeActiveElement()
  //   this.searchField.focus()
  //   this.bodyEl.classList.add('search-active')
  // }

  // Close the search bar
  // closeSearch(){
  //   this.searchOpen = false;
  //   this.restoreActiveElement()
  //   this.bodyEl.classList.remove('search-active')
  // }

  searchOnFocus(el) {
    el.parentElement.classList.add('focused');
  }

  searchOnBlur(el) {
    el.parentElement.classList.remove('focused');
  }

  // close all the opened
  closeAll() {
    this.closeNav()
    // this.closeSearch()
    this.closeInternalNav()
  }

  resizeHandle() {
    this.siteNavEl.style.height = `${viewport().height - this.headerNav.getBoundingClientRect().height}px`;
    if ( this.internalNavOpen && viewport().width > 1200 && this.siteHeaderInternalNav.classList.contains('desktop-view') ) {
      this.closeInternalNav();
    }
  }

  /**
   * Store the element that activated the action so it can be returned to after
   *
   * @memberof Header
   */
  storeActiveElement() {
    this.previouslyActiveElement = document.activeElement;
  }

  /**
   * Restore the focus to the trigger
   *
   * @memberof Header
   */
  restoreActiveElement() {
    this.previouslyActiveElement.focus()
  }


  // scroll to top
  scrollToTop() {
    TweenLite.to(window, 0.8, { ease: Power2.easeInOut, scrollTo: { y: 0, autoKill: false } });
  }

}
